import * as fs from "fs";
import * as ko from "knockout";
import Navigo = require("navigo");
import { Postbox } from "../postbox/postbox";

export class DialogViewModel {
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalSelectedObject = ko.observable(null);
    modalHandleSelection = ko.observable();
    givenId = "modal";
    filterType = '';
    modalType = ko.observable('');
    query = ko.observable('');
    nameList = ko.observableArray([]);
    initialNames = [];
    filteredItems: ko.Computed<any>;
    allItems: ko.ObservableArray<any>;
    entries: ko.Computed<Array<any>>;
    filter = ko.observable('');
    initialfocusinput = true;
    isCase = ko.observable(false);

    modalEdit() {

        console.log('here');
        switch (this.modalTitle()) {
            case "Referat": {
                break;
            }
            case "Sachbearbeiter": {
                break;
            }
            case "Referat": {
            }
        }
    }


    modalPick() {

        if (this.modalHandleSelection !== null) this.modalHandleSelection()(this.modalSelectedObject);
        $(`#${this.givenId}`).modal('hide');
    };



    modalSelect = (obj, event) => {

        if ($(event.target.parentElement).hasClass("active")) {
            this.modalPick();
        }
        else {
            $("#modalTable tr").removeClass("active");
            $(event.target.parentElement).addClass("active");
            this.modalSelectedObject(obj);
        }
    }

    filteredNames = ko.computed(() => {
        if (!this.query()) {
            return this.nameList();
        } else {
            return this.nameList()
                .filter(name => {
                    console.log('name: ', name);
                    return name.NameID.toLowerCase().indexOf(this.query().toLowerCase()) > -1;
                });
        }
    })

    checkType() {
        if (this.modalType() == 'fastcase') {
            return this.filteredNames;
        } else {
            return this.modalData;
        }
    }

    focusinput() {
        if (this.initialfocusinput) {
            document.getElementById("input-filter").focus();
            this.initialfocusinput = false;
        }
    }

    router = new Navigo(null, true);

    openCreateCase() {
        $(`#${this.givenId}`).modal('hide');
        var mb = document.getElementsByClassName("modal-backdrop")
        for (let i = 0; i < mb.length; i++)
            mb[i].remove();

        setTimeout(() => {
            this.router.navigate("/easycase")
        }, 250)

    }

    constructor(params: any) {
        if (params.prefix) {
            this.givenId = params.prefix + this.givenId;
        }

        this.modalTitle = params.modalTitle;
        this.modalKeys = params.modalKeys;
        this.modalColumns = params.modalColumns;
        this.modalData = params.modalData;
        this.allItems = params.modalData;
        this.modalHandleSelection = params.modalHandleSelection;
        this.modalType(params.type);
        this.nameList([]);

        this.modalTitle.subscribe(() => {
            if (ko.toJS(this.modalTitle) === "Akte") {
                this.isCase(true);
            }
            else
                this.isCase(false);
        })

        this.modalData.subscribe((data) => {
            data.forEach((name) => {
                this.nameList.push(name);
            });

        });

        this.filteredItems = ko.computed({
            owner: this,
            read: () => {
                let filter = this.filter().toLowerCase();
                let items = this.allItems();
                return items.filter(item => {
                    for (let value of ko.toJS(this.modalKeys).map(column => item[column]) as Array<string>) {
                        if (value && value.toString().trim().toLowerCase().indexOf(filter) !== -1) return true;
                    }
                    return false;
                })

            }
        })
        this.entries = ko.computed({
            owner: this,
            read: () => {
                return this.filteredItems();
            }
        })

        Postbox.publisher().subscribe(() => this.filter(''), "ResetDialogFilterOption");
    }

}

let html = fs.readFileSync(__dirname + '/dialog.html', 'utf8');

ko.components.register("dialog-view", {
    viewModel: DialogViewModel,
    template: html
});
