import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import '../extended/new';
import '../dialog/AppointmentDialog'
import { IntermediateListItem, Color } from '../dataTable/dataTable';
import { Utils } from '../../utils';
import * as moment from "moment"
import "../../../node_modules/moment/locale/de.js";
import * as fs from 'fs';
import { Postbox } from "../postbox/postbox";
import "../dialog/CompleteDeadlineDialog";
import "../dialog/CompleteAppointmentDialog";
import { RolesAndRights } from "../../helpers/RolesAndRights";

export class TfwViewModel {
    appointments = ko.observableArray([]);
    deadlines = ko.observableArray([]);

    todos = ko.observableArray([]);

    newSB = ko.observable("");
    openDashboardAppointment = ko.observable();
    appointmentId = ko.observable();
    filter = ko.observable("");
    sachbearbeiter = ko.observable("");
    selectedVB = ko.observable("");
    ProcessPartner = ko.observable("");
    ProcessPartnerId = ko.observable("");
    Fristenkontrolle = ko.observable("");
    FristenkontrolleId = ko.observable("");

    rStartDate = ko.observable("");
    rEndDate = ko.observable("");
    dailyReportDate = ko.observable("");

    values = ko.observable({
        complete: ko.observable(true),
        yellow: ko.observable(true),
        red: ko.observable(true),
        bell: ko.observable(true)
    })
    types = ko.observable({
        Frist: ko.observable(true),
        Vorfrist: ko.observable(true),
        Termin: ko.observable(true)
    })
    currentID = ko.observable('');
    hasButton = ko.observable(true);

    extendDeadlineDate = ko.observable();
    extendPreDeadlineDate = ko.observable();
    extendDeadlineNote = ko.observable();
    extendedPreDeadlines = ko.observableArray([]);

    PreDeadlinesLength = ko.observable(0);
    extendDeadlineClerk = ko.observable();
    checkDeadline = ko.observable(true);
    checkPreDeadline = ko.observable(true);
    checkApp = ko.observable(true);

    checkResub = ko.observable(true);

    tooltips = ko.observableArray([{ name: 'TypeId', col: 'subject' }])
    showTermine = ko.observable(false);
    showFristen = ko.observable(false);
    showVorFristen = ko.observable(false);
    showFTWFilter = ko.observable(true);

    calledfirst = ko.observable(true);
    allStandorte = ko.observableArray([]);

    hasReferatCount = ko.observable(0);
    hasFKACount = ko.observable(0);

    currentRubrum = ko.observable('');
    AllReferate = ko.observable();
    showNotification = ko.observable(false);
    showParameterMessage = ko.observable(false);
    emptyStandorte = ko.observable(true);
    allDeadlineExtensionReasons = ko.observableArray([]);
    allExtendsionReasonsLength = ko.observable(0);
    extendDeadlineReason = ko.observable('');
    instancesInCase = ko.observableArray([]);
    rejectionReason = ko.observable('');
    rejectionReasonAppointment = ko.observable('');
    isAppointmentHistoryModal = ko.observable(true);
    isDeadlineHistoryModal = ko.observable(true);

    fKAId = ko.observable('');
    fkAName = ko.observable('');

    showError = ko.observable(false);
    showExternError = ko.observable(false);
    showSuccess = ko.observable(false);
    showExternSuccess = ko.observable(false);
    showOutlookLoader = ko.observable(false);
    OutlookAccounts = ko.observableArray([]);

    selectedFilterStandort = ko.observable("");
    selectedFilterStandortKontrollblatt = ko.observable("");
    selectedFilterAccount = ko.observable("");
    selectedFilterAccountKontrollblatt = ko.observable("");
    selectedInstance = ko.observable("");
    pastFristApproved = ko.observable(false);

    showExternalExportSuccess = ko.observable(false);
    showOutlookExportSuccess = ko.observable(false);
    showExternalExportError = ko.observable(false);
    showOutlookExportError = ko.observable(false);

    isEditedObjTransfered = ko.observable(true);

    isViewerRole = ko.observable<boolean>(false);

    attendees = ko.observableArray([]);

    startRepDeadline = ko.observable('');
    endRepDeadline = ko.observable('');
    caseRepDeadline = ko.observable('');
    deadlineLength = ko.observable('');
    allUsers = ko.observableArray();


    showPrintLoader = ko.observable(false);
    showPrintError = ko.observable(false);
    showPrintErrorNoDeadlines = ko.observable(false);
    showPrintErrorNoAppointments = ko.observable(false);

    showReportError = ko.observable(false);
    showReportVBError = ko.observable(false);

    ReportFilter = ko.observable({
        CaseID: ko.observable(""),
        DoneOnly: ko.observable(false),
        Locations: ko.observableArray(null),
        ClerkID: ko.observable(""),
        Standorte: ko.observableArray(null)
    })

    readonly DANGER_COLOR: Color = {
        color: "white",
        "background-color": "#e21b38"
    }

    currentView = ko.observable("ftw");
    filteredTodos: ko.Computed<any>;

    filterType(type: string) {
        if (type == 'frist') {
            this.types().Frist(this.checkDeadline())
        }
        else if (type == 'vorfrist') {
            this.types().Vorfrist(this.checkPreDeadline())
        }
        else if (type == 'termin') {
            this.types().Termin(this.checkApp())
        }
        this.filter(this.filter());
    }

    deadlineToBeModified = ko.observable(null);
    appointmentToBeModified = ko.observable(null);

    hasVerificationRights = ko.observable(false);

    filterWithStars(item, filters) {
        let fil = filters.filter(a => a === "bell");
        let verifyFilter = false;
        if (fil.length === 1)
            verifyFilter = true;

        if (verifyFilter) {
            if ((item['stars'] && filters.indexOf(item['stars']) !== -1) || (item.originalObj.HasToBeVerified && (item.originalObj.VerifiedOn === null || (item.originalObj.VerifiedOn !== null && item.originalObj.IsVerificationAccepted !== true && item.originalObj.CompletionDate === null)))) return true;
        }
        else
            if (item['stars'] && filters.indexOf(item['stars']) !== -1) return true;

        return false
    }

    filterWithVB(item, filters) {
        let isInVb = false;
        for (let i = 0; i < item.originalObj.VBs.length; i++) {
            if (item.originalObj.VBs[i].Sachbearbeiter_ID === filters)
                isInVb = true;
        }

        return isInVb;
    }

    filterWithType(item, filters) {
        if (item['entryTypeName'] && filters.indexOf(item['entryTypeName']) !== -1) return true
        return false
    }

    filterWithStandort(item) {
        if (item['ref'] && ko.toJS(this.selectedFilterStandort) === item['ref']) return true
        return false
    }

    filterWithFKA(item) {
        if (item['fka'] && ko.toJS(this.selectedFilterAccount) === item['fka']) return true
        return false
    }
    filterWithInstance(item) {
        if (item['instanceId'] && ko.toJS(this.selectedInstance) === item['instanceId']) return true
        return false
    }

    getNextWV(items) {
        if (items.length > 0) {
            let found = false;
            let intervall = 1;
            let notify = false;
            for (let i = items.length - 1; i >= 0; i--) {
                if (items[i].stars === "red")
                    notify = true;
                if (items[i].entryType === "resubmission") {
                    if (Number(moment(ko.toJS(items[i].date), "DD.MM.YYYY").format("YYYYMMDD")) >= Number(moment().format("YYYYMMDD"))) {
                        found = true;
                    }
                    else {
                        if (found) {
                            $("#NextWVCase").removeClass("hide");
                            $("#NextWVCaseLbl").removeClass("hide");
                            $("#grundcaseWV").val(items[i + intervall].subject);
                            $("#deadlineDateCaseWV").val(moment(items[i + intervall].date, "DD.MM.YYYY").format("YYYY-MM-DD"));
                            $("#SBcaseWV").val(items[i + intervall].SB);
                            break;
                        }
                    }
                    intervall = 1;
                }
                else {
                    intervall++;
                }
            }

            if (notify) {
                $("#redNotificationCircle").attr("style", "font-size: 0.75rem; vertical-align: middle !important; margin-bottom: 0.4rem; display: inline-block");
                $("#redNotificationCircleTFW").attr("style", "font-size: 0.5rem; vertical-align: middle !important; margin-bottom: 0.1rem; display: inline-block")
            }
        }
    }

    public getVM = () => this;

    async updateTodos(allAppointments: any, allDeadlines: any) {
        try {
            let appointments = allAppointments.Payload.Appointments;
            let deadlines = allDeadlines.Payload.Deadlines;

            let transformedAppointments = appointments.map(obj => this.transformAppointmentData(obj, "appointment"));
            if (transformedAppointments.length > 0)
                this.showTermine(true);
            else
                this.showTermine(false);

            let startAsNumber = Number(moment(ko.toJS(this.rStartDate)).format("YYYYMMDD"));
            let endAsNumber = Number(moment(ko.toJS(this.rEndDate)).format("YYYYMMDD"));
            this.showVorFristen(false);
            let transformedDeadlines = deadlines.map(obj => this.transformDeadlineData(obj, "deadline", startAsNumber, endAsNumber));

            if (transformedDeadlines.length > 0)
                this.showFristen(true);

            else
                this.showFristen(false);

            let transformedResubmissions = [];

            let todos: any;
            if (this.checkDeadline() && !this.checkResub() && this.checkApp()) {
                todos = (transformedAppointments.concat(transformedDeadlines));
            }
            if (this.checkDeadline() && this.checkResub() && !this.checkApp()) {
                todos = (transformedDeadlines.concat(transformedResubmissions));
            }
            if (!this.checkDeadline() && this.checkResub() && this.checkApp()) {
                todos = (transformedAppointments
                    .concat(transformedResubmissions));
            }
            if ((this.checkDeadline() && this.checkResub() && this.checkApp()) || !this.checkDeadline() && !this.checkResub() && !this.checkApp()) {
                todos = (transformedAppointments.concat(transformedDeadlines)
                    .concat(transformedResubmissions));
            }
            if ((this.checkDeadline() && (!this.checkResub() && !this.checkApp()))) {
                todos = transformedDeadlines;
            }
            if ((!this.checkDeadline() && this.checkResub()) && !this.checkApp()) {
                todos = transformedDeadlines;
            }
            if ((!this.checkDeadline() && (this.checkResub() && !this.checkApp()))) {
                todos = transformedResubmissions;
            }
            if ((!this.checkDeadline() && (!this.checkResub() && this.checkApp()))) {
                todos = transformedAppointments;
            }

            this.showFTWFilter((ko.toJS(this.showFristen) && ko.toJS(this.showTermine)) || (ko.toJS(this.showFristen) && ko.toJS(this.showVorFristen)) || (ko.toJS(this.showTermine) && ko.toJS(this.showVorFristen)));

            let sortedTodos = todos.sort((t1: IntermediateListItem, t2: IntermediateListItem) => {
                if (t1.dateObj.isBefore(t2.dateObj)) {
                    return 1;
                } else {
                    return -1;
                }
            }).map(obj => {
                if ((obj.entryTypeName === "Frist" || obj.entryType === "ecase" || obj.entryTypeName === "Vorfrist" || obj.entryTypeName === "Wiedervorlage" || obj.entryTypeName === "Termin") && !this.isViewerRole()) {
                    obj.columnActions = {
                        "number": MainViewModel.RoutingTable.generateLink(`/new/${encodeURIComponent(obj.number)}`)
                    };
                }
                return obj;
            }).reverse();

            this.todos(sortedTodos);
        }
        catch (e) {
            console.log(e);
        }
    }

    transformAppointmentData(obj: any, type: string) {
        let actionList = [];
        obj.CreatedAtDateFormated = ko.toJS(moment(ko.toJS(obj.CreationDate)).format("DD.MM.YYYY"));
        obj.CreatedFromFormated = ko.toJS(this.getUserForShorthand(obj.CreatorId));

        if (obj.EditedAtDate !== null) {
            obj.EditedAtDateFormated = ko.toJS(moment(ko.toJS(obj.EditedAtDate)).format("DD.MM.YYYY"));
            obj.EditedFromFormated = ko.toJS(this.getUserForShorthand(obj.EditorId));
        }

        if (obj.VerifiedOn !== null) {
            obj.VerifiedOnFormated = ko.toJS(moment(ko.toJS(obj.VerifiedOn)).format("DD.MM.YYYY"));
            obj.VerifiedByFormated = ko.toJS(this.getUserForShorthand(obj.VerifiedBy));
        }

        obj.StartFormated = ko.toJS(moment(ko.toJS(obj.StartDate)).format("DD.MM.YYYY HH:mm")) + " Uhr";
        obj.EndFormated = ko.toJS(moment(ko.toJS(obj.EndDate)).format("DD.MM.YYYY HH:mm")) + " Uhr";

        if (ko.toJS(obj.StartFormated).endsWith("00:00 Uhr") && ko.toJS(obj.EndFormated).endsWith("23:59 Uhr")) {
            obj.StartFormated = ko.toJS(moment(ko.toJS(obj.StartDate)).format("DD.MM.YYYY"));
            obj.EndFormated = ko.toJS(moment(ko.toJS(obj.EndDate)).format("DD.MM.YYYY"));
        }

        obj.AppointmentTypeFormated = this.getTypeOfAppointment(obj.AppointmentType);

        let history = {
            icon: "history", name: "Verlauf", action: async () => {
                this.isAppointmentHistoryModal(true);
                this.appointmentToBeModified(obj);
                this.abortRejectAppointment();
                $('#verifyAppointmentModal').modal('show');
            }
        }

        actionList.push(history);

        let verificationCheckMuted = {
            icon: "user-check text-muted", name: "Dieser Termin muss noch von einem zweiten Sachbearbeiter freigegeben werden!", action: async () => { }
        }

        let verificationCheck = {
            icon: "check-double", name: "Dieser Termin muss noch von einem zweiten Sachbearbeiter freigegeben werden!", action: async () => {
                this.isAppointmentHistoryModal(false);
                this.appointmentToBeModified(obj);
                this.abortRejectAppointment();
                $('#verifyAppointmentModal').modal('show');
            }
        }

        let verificationRejected = {
            icon: "user-times", name: "Dieser Termin wurde von einem Sachbearbeiter als fehlerhaft markiert, bitte bearbeiten Sie die Daten dementsprechend!", action: async () => { }
        }

        let verificationRejectedMuted = {
            icon: "user-times text-muted", name: "Dieser Termin wurde von einem Sachbearbeiter als fehlerhaft markiert, der Ersteller muss diesen entsprechend anpassen!", action: async () => { }
        }

        let editAppointment = {
            icon: "pencil-alt", name: "Bearbeiten", action: () => {
                this.openDashboardAppointment(true)
                this.IsEditMode(true)
                this.appointmentId(obj.Id)
                Postbox.publisher().publish(obj.Id, "FillAppointmentDataWithID");
                $("#AppointmentTFWmodal").modal("show");
            }
        }

        let deleteAppointment = {
            icon: "trash-alt", name: "Löschen", action: async () => {
                Postbox.publisher().publish({ Id: obj.Id, Complete: false }, "CompleteAppointmentModalData");
            }
        }

        let checkAppointment = {
            icon: "check", name: "Abhaken", action: async () => {
                Postbox.publisher().publish({ Id: obj.Id, Complete: true }, "CompleteAppointmentModalData");
            }
        }

        if (!obj.Completed) {
            if (!this.isViewerRole()) {
                actionList.push(deleteAppointment);

                if (obj.HasToBeVerified) {
                    if (obj.VerifiedOn !== null) {
                        if (!obj.IsVerificationAccepted) {
                            if (obj.CreatorId === RNSAPI.getCurrentLoggedInUser().ShortHandSymbol) {
                                actionList.push(verificationRejected);
                                actionList.push(editAppointment);
                            }
                            else
                                actionList.push(verificationRejectedMuted);
                        }
                        else
                            actionList.push(editAppointment);
                    }

                }
                else {
                    actionList.push(editAppointment);
                }

                if (obj.HasToBeVerified) {
                    if (obj.VerifiedOn !== null && obj.IsVerificationAccepted)
                        actionList.push(checkAppointment);

                }
                else
                    actionList.push(checkAppointment);
            }
        }

        let stars = "";
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        let d = new Date(obj.EndDate);
        d.setHours(0, 0, 0, 0);
        if (obj.Completed)
            stars = "complete"
        else {
            if (d < today) {
                stars = "red";
            } else {
                stars = "yellow";
            }
        }

        if (ko.toJS(this.AllReferate).length > 0) {
            let refs = ko.toJS(this.AllReferate);
            let loc = refs.filter(a => a.Id == obj.LocationId)
            if (loc.length === 1)
                obj.locationID = loc[0].Name;
            else
                obj.locationID = "";
        }
        else
            obj.locationID = "";

        obj.fka = obj.Fka;

        if (ko.toJS(this.instancesInCase).length > 0) {
            obj.CourtInstanceId = obj.InstanceId
            for (let inst of ko.toJS(this.instancesInCase)) {
                if (obj.InstanceId === inst.InstanceId) {
                    obj.Instance = inst.InstanceType + " " + inst.CourtId;
                    break;
                }
            }
            if (!obj.Instance)
                obj.Instance = "";
        }

        if (obj.HasToBeVerified && obj.VerifiedOn === null && obj.CreatorId === RNSAPI.getCurrentLoggedInUser().ShortHandSymbol) {
            actionList.push(verificationCheckMuted);
        }

        if (obj.HasToBeVerified && obj.VerifiedOn === null && obj.CreatorId !== RNSAPI.getCurrentLoggedInUser().ShortHandSymbol) {
            actionList.push(verificationCheck);
        }

        let vbs = "";
        for(let vb of obj.VBs)
        {
            if(vbs === "")
                vbs = vb.Sachbearbeiter_ID;
            else
                vbs = vbs + ' ' + vb.Sachbearbeiter_ID;
        }

        return new IntermediateListItem(stars, type, nameTable[type], obj.StartDate, obj.AppointmentSubject, obj.CaseId, obj, actionList, undefined, obj.Rubrum, obj.SB, obj.AppointmentNote, obj.locationID, obj.fka, obj.Instance, obj.CourtInstanceId, null, vbs);
    }

    transformDeadlineData(obj: any, type: string, start: Number, end: Number) {
        obj.Note = obj.Note1;
        obj.CreatedAtDateFormated = ko.toJS(moment(ko.toJS(obj.CreationDate)).format("DD.MM.YYYY"));
        obj.CreatedFromFormated = ko.toJS(this.getUserForShorthand(obj.CreatedBy));
        obj.StartDateFromtated = ko.toJS(moment(ko.toJS(obj.StartDate)).format("DD.MM.YYYY"));
        obj.DeadlineFormated = ko.toJS(moment(ko.toJS(obj.Deadline)).format("DD.MM.YYYY"));

        if (obj.PreDeadline)
            obj.PreDeadlineFormated = ko.toJS(moment(ko.toJS(obj.PreDeadline)).format("DD.MM.YYYY"));

        let deadlineAsNumber = Number(moment(obj.Deadline).format("YYYYMMDD"));


        if (deadlineAsNumber < start || deadlineAsNumber > end) {
            console.log(1)
            this.showVorFristen(true);
            type = "predeadline";
        }

        if (obj.EditedAt !== null) {
            obj.EditedAtDateFormated = ko.toJS(moment(ko.toJS(obj.EditedAt)).format("DD.MM.YYYY"));
            obj.EditedFromFormated = ko.toJS(this.getUserForShorthand(obj.EditedFromId));
        }

        if (obj.VerifiedOn !== null) {
            obj.VerifiedOnFormated = ko.toJS(moment(ko.toJS(obj.VerifiedOn)).format("DD.MM.YYYY"));
            obj.VerifiedByFormated = ko.toJS(this.getUserForShorthand(obj.VerifiedBy));
        }

        if (obj.CompletionDate !== null) {
            obj.CompletedByFormated = ko.toJS(this.getUserForShorthand(obj.CompletedBy));
            obj.CompletionDateFormated = ko.toJS(moment(ko.toJS(obj.CompletionDate)).format("DD.MM.YYYY"));
        }
        if (!obj.PreDeadline2)
            obj.PreDeadline2 = obj.PreDeadline;

        obj.PreDeadline2 = ko.toJS(moment(ko.toJS(obj.PreDeadline)).format("DD.MM.YYYY"));
        obj.PreDeadlinesFormated = [];

        for (let i = 0; i < obj.PreDeadlines.length; i++) {
            var formatedPreDeadline = ko.toJS(moment(ko.toJS(obj.PreDeadlines[i])).format("DD.MM.YYYY"));
            obj.PreDeadline2 = obj.PreDeadline2 + "      " + formatedPreDeadline;
            obj.PreDeadlinesFormated.push(formatedPreDeadline);
        }

        let check = [
            {
                icon: "check", name: "Abschließen", action: async () => {
                    obj.CompletionDate = "";
                    obj.CompletionDate2 = "";
                    Postbox.publisher().publish({ Reason: obj.TypeDescription, CaseId: obj.CaseId, Id: obj }, "CompleteDeadlineModalData");
                }
            }

        ];

        let delegate = [
            {
                icon: "user-circle", name: "Delegieren", action: async () => {
                    this.deadlineToBeModified(obj);
                    this.getFKA(obj.CaseId);
                    $('#delegateDeadlineModal').modal('show');
                    $('#newSB').val(ko.toJS(obj.Clerk));
                }
            }
        ];

        let extendable = [
            {
                icon: "pencil-alt", name: "Verlängern", action: async () => {
                    this.deadlineToBeModified(obj);
                    let preDeadlines = this.deadlineToBeModified().PreDeadlines;
                    this.getFKA(obj.CaseId);
                    $('#extendDeadlineModal').modal('show');
                    this.editPastFrist();

                    if (obj.EditedObj !== true) {
                        this.extendDeadlineDate(ko.toJS(moment(this.deadlineToBeModified().Deadline).format("YYYY-MM-DD")));
                        this.extendPreDeadlineDate(ko.toJS(moment(this.deadlineToBeModified().PreDeadline).format("YYYY-MM-DD")));
                        for (let i = 0; i < preDeadlines.length; i++) {
                            preDeadlines[i] = ko.toJS(moment(preDeadlines[i]).format("YYYY-MM-DD"));
                        }
                    }
                    else {
                        this.extendDeadlineDate(ko.toJS(moment(this.deadlineToBeModified().Deadline, "DD.MM.YYYY").format("YYYY-MM-DD")));
                        this.extendPreDeadlineDate(ko.toJS(moment(this.deadlineToBeModified().PreDeadline, "DD.MM.YYYY").format("YYYY-MM-DD")));
                        for (let i = 0; i < preDeadlines.length; i++) {
                            preDeadlines[i] = ko.toJS(moment(preDeadlines[i], "DD.MM.YYYY").format("YYYY-MM-DD"));
                        }
                    }

                    console.log(obj);

                    this.isEditedObjTransfered(ko.toJS(obj.WasExportedToOutlook));

                    this.extendDeadlineNote(ko.toJS(this.deadlineToBeModified().Note));
                    this.extendedPreDeadlines(preDeadlines);
                    this.PreDeadlinesLength(this.extendedPreDeadlines().length);
                    this.extendDeadlineClerk(RNSAPI.getCurrentLoggedInUser().ShortHandSymbol)
                }
            }
        ];

        let verificationCheckMuted = {
            icon: "user-check text-muted", name: "Diese Frist muss noch von einem zweiten Sachbearbeiter freigegeben werden!", action: async () => { }
        }

        let verificationRejected = {
            icon: "user-times", name: "Diese Frist wurde von einem Sachbearbeiter als fehlerhaft markiert, bitte bearbeiten Sie die Daten dementsprechend!", action: async () => { }
        }

        let verificationCheck = {
            icon: "check-double", name: "Diese Frist muss noch von einem zweiten Sachbearbeiter freigegeben werden!", action: async () => {
                this.getDeadlineLength(obj.TypeId);
                this.isDeadlineHistoryModal(false);
                this.deadlineToBeModified(obj);
                this.abortRejectDeadline();
                $('#historyOrVerifcationDeadlineModal').modal('show');
            }
        }

        let actionList = [
            {
                icon: "history", name: "Verlauf", action: async () => {
                    this.getDeadlineLength(obj.TypeId);
                    this.isDeadlineHistoryModal(true);
                    this.deadlineToBeModified(obj);
                    this.abortRejectDeadline();
                    $('#historyOrVerifcationDeadlineModal').modal('show');
                }
            }
        ];

        if (ko.toJS(this.AllReferate).length > 0) {
            let refs = ko.toJS(this.AllReferate);
            let loc = refs.filter(a => a.Id == obj.LocationId)
            if (loc.length === 1)
                obj.locationID = loc[0].Name;
            else
                obj.locationID = "";
        }
        else
            obj.locationID = "";

        obj.fka = obj.FKAName;

        if (obj.CompletionDate === null && !this.isViewerRole()) {

            if (obj.HasToBeVerified) {
                if (obj.VerifiedOn !== null && obj.IsVerificationAccepted)
                    actionList.push(delegate[0]);
            }
            else
                actionList.push(delegate[0]);

            if (!ko.toJS(this.isNotfrist(obj.TypeId))) {
                if (obj.HasToBeVerified) {
                    if (obj.VerifiedOn !== null && obj.IsVerificationAccepted === true)
                        actionList.push(extendable[0]);
                }
                else
                    actionList.push(extendable[0]);
            }

            if (obj.HasToBeVerified) {
                if (obj.VerifiedOn !== null && obj.IsVerificationAccepted)
                    actionList.push(check[0]);
            }
            else
                actionList.push(check[0]);


            if (obj.HasToBeVerified) {
                if (obj.IsVerificationAccepted === false) {
                    actionList.push(verificationRejected);
                    actionList.push(extendable[0]);
                }
            }

            if (obj.HasToBeVerified && obj.VerifiedOn === null && obj.CreatedBy === RNSAPI.getCurrentLoggedInUser().ShortHandSymbol) {
                actionList.push(verificationCheckMuted);
            }

            if (obj.HasToBeVerified && obj.VerifiedOn === null && obj.CreatedBy !== RNSAPI.getCurrentLoggedInUser().ShortHandSymbol) {
                actionList.push(verificationCheck);
            }
        }

        let stars = "";
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        let d = new Date(obj.Deadline);
        d.setHours(0, 0, 0, 0);
        if (obj.CompletionDate !== "" && obj.CompletionDate !== null) {
            stars = "complete";
        }
        else {

            if (d < today) {
                if (obj.CompletionDate == "" || obj.CompletionDate == null) {
                    stars = "red";
                } else {
                    stars = "complete";
                }
            }
            else {
                stars = "yellow";
            }
        }

        if (ko.toJS(this.instancesInCase).length > 0) {
            obj.InstanceId = obj.CourtInstanceId;
            for (let inst of ko.toJS(this.instancesInCase)) {
                if (obj.CourtInstanceId === inst.InstanceId) {
                    obj.Instance = inst.InstanceType + " " + inst.CourtId;
                    break;
                }
            }
            if (!obj.Instance)
                obj.Instance = "";
        }

        let vbs = "";
        for(let vb of obj.VBs)
        {
            if(vbs === "")
                vbs = vb.Sachbearbeiter_ID;
            else
                vbs = vbs + ' ' + vb.Sachbearbeiter_ID;
        }

        return new IntermediateListItem(stars, type, nameTable[type], obj.Deadline, obj.TypeDescription, obj.CaseId, obj, actionList, undefined, obj.Rubrum, obj.SB, obj.Note, obj.locationID, obj.fka, obj.Instance, obj.CourtInstanceId, obj.PreDeadline2, vbs);
    }

    removePreDeadline(index) {
        this.extendedPreDeadlines.splice(index, 1);
        this.PreDeadlinesLength(this.extendedPreDeadlines().length);
    }

    addPreDeadline() {
        this.extendedPreDeadlines.push(ko.observable(moment().format("YYYY-MM-DD")));
        this.PreDeadlinesLength(this.extendedPreDeadlines().length);
    }

    async verifyDeadline() {
        let res = await RNSAPI.verifyDeadline(this.deadlineToBeModified());
        if (res.Type === "CompletionSuccessful") {
            this.update();
            $('#historyOrVerifcationDeadlineModal').modal('hide');
        }
        else {
            //Show Error in Verify Modal
        }
    }

    async rejectDeadline() {
        if (!ko.toJS(this.rejectionReason)) {
            $("#reasonForRejection_inp").addClass("has-error");
            return;
        }

        $("#reasonForRejection_inp").removeClass("has-error");

        this.deadlineToBeModified().ReasonForRejection = ko.toJS(this.rejectionReason);

        let res = await RNSAPI.rejectDeadline(this.deadlineToBeModified());
        if (res.Type === "CompletionSuccessful") {
            this.update();
            $('#historyOrVerifcationDeadlineModal').modal('hide');
        } else {
            //Show Error in Verify Modal
        }
    }

    async verifyAppointment() {
        let res = await RNSAPI.verifyAppointment(this.appointmentToBeModified().Id);
        if (res.Type === "AppointmentCompleted") {
            this.update();
            $('#verifyAppointmentModal').modal('hide');
        }
        else {
            //Show Error in Verify Modal
        }
    }

    async rejectAppointment() {
        if (!ko.toJS(this.rejectionReasonAppointment)) {
            $("#reasonForRejectionAppointment_inp").addClass("has-error");
            return;
        }
        $("#reasonForRejectionAppointment_inp").removeClass("has-error");
        let res = await RNSAPI.rejectAppointment(this.appointmentToBeModified().Id, ko.toJS(this.rejectionReasonAppointment));
        if (res.Type === "AppointmentCompleted") {
            this.update();
            $('#verifyAppointmentModal').modal('hide');
        } else {
            //Show Error in Verify Modal
        }
    }

    isNotfrist(TypeId: string) {
        switch (TypeId) {
            case "AV":
                return true;
            case "B":
                return true;
            case "ER":
                return true;
            case "ES":
                return true;
            case "NZ":
                return true;
            case "R":
                return true;
            case "SB":
                return true;
            case "RV":
                return true;
            case "We":
                return true;
            case "EP":
                return true;
            case "WK":
                return true;
            case "GE":
                return true;
            case "EA":
                return true;
            case "Er":
                return true;
            case "WA":
                return true;
            default:
                return false;
        }
    }

    getUserForShorthand(shorthand: string) {
        for (let i = 0; i < this.allUsers().length; i++) {
            if (ko.toJS(this.allUsers())[i].Shorthandsymbol === shorthand)
                return ko.toJS(this.allUsers())[i].Name.replace('Bitte wählen Sie', '').replace('Frau', '').replace('Herr', '');
        }
        return "";
    }

    async getAllUsers() {
        let res = (await RNSAPI.getLawFirmUsers()).Payload.Users
        this.allUsers(res);
    }

    async getDeadlineLength(TypeId: string) {
        let res = (await RNSAPI.getDeadlineReasons()).Payload.Reasons
        for (let i = 0; i < res.length; i++) {
            if (TypeId === res[i].DeadlineNumber) {
                if (res[i].DeadlineDuration !== "") {
                    this.deadlineLength(this.formatDeadlineDuration(res[i].DeadlineDuration, res[i].PreDeadlineDuration));
                }
                break;
            }
        }
    }

    formatDeadlineDuration(deadduration: string, preduration = "") {
        let duration_names = { s: { t: 'Tag', w: 'Woche', m: 'Monat', j: 'Jahr' }, p: { t: 'Tage', w: 'Wochen', m: 'Monate', j: 'Jahre' } }
        let oldDuration = ko.toJS(deadduration).slice(1);
        let durationtext = "";
        if (oldDuration.startsWith("0"))
            oldDuration = oldDuration.slice(1);
        if (/^\w\d+$/.test(deadduration)) {
            let duration = Number(deadduration.slice(1));
            let dtype = deadduration[0];
            durationtext = duration + ' ' + duration_names[duration == 1 ? 's' : 'p'][dtype];
        }
        if (ko.toJS(preduration) !== "") {
            try {
                let days = parseInt(preduration)
                if (days > 1)
                    preduration = preduration + " Tage";
                else
                    preduration = preduration + " Tag";

                durationtext = durationtext + " | Vorfrist: " + preduration;
            } catch { }
        }
        return durationtext;
    }

    async printDailyReportDeadline() {
        (<HTMLButtonElement>document.getElementById("printDailyReportDeadline_btn")).disabled = true;
        this.showNotification(false);
        if (ko.toJS(this.selectedFilterStandortKontrollblatt) !== '' && ko.toJS(this.selectedFilterAccountKontrollblatt) !== '') {
            this.showNotification(true);
            setTimeout(() => {
                this.showNotification(false);
                (<HTMLButtonElement>document.getElementById("printDailyReportDeadline_btn")).disabled = false;
            }, 3000);
            return;
        }

        this.dailyReportDate((<HTMLInputElement>document.getElementById("dailyReportDate")).value);
        this.showPrintLoader(true);
        try {
            let res = (await RNSAPI.reportDeadlinePerDay(moment(ko.toJS(this.dailyReportDate), "YYYY-MM-DD"), ko.toJS(this.selectedFilterStandortKontrollblatt), ko.toJS(this.selectedFilterAccountKontrollblatt)));
            if (res.Type === "ReportPerDaySuccessful") {
                setTimeout(() => { }, 1000)
                let from = moment(ko.toJS(this.dailyReportDate), "YYYY-MM-DD").format("DD.MM.YYYY");

                let element = document.createElement('a');
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(res.Payload.DocumentData);
                } else {
                    element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + res.Payload.DocumentData);
                    element.setAttribute('download', `${'Kontrollblatt vom ' + from + '(' + ko.toJS(this.selectedFilterStandortKontrollblatt) + ')'}.docx`);

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                    (<HTMLButtonElement>document.getElementById("printDailyReportDeadline_btn")).disabled = false;
                    $("#reportDailyDeadlineModal").modal("hide");
                    this.showPrintLoader(false);
                }
            }
            else if (res.Type === "ReportPerDayNoEntries") {
                this.showPrintErrorNoDeadlines(true);
                this.showPrintError(false);
                this.showPrintLoader(false);
                setTimeout(() => {
                    this.showPrintErrorNoDeadlines(false);
                    (<HTMLButtonElement>document.getElementById("printDailyReportDeadline_btn")).disabled = false;
                }, 3000);
            }
            else {
                this.showPrintErrorNoDeadlines(false);
                this.showPrintError(true);
                this.showPrintLoader(false);
                setTimeout(() => {
                    this.showPrintError(false);
                    (<HTMLButtonElement>document.getElementById("printDailyReportDeadline_btn")).disabled = false;
                }, 3000);
            }
        }
        catch {
            this.showPrintLoader(false);
            this.showPrintErrorNoDeadlines(false);
            this.showPrintError(true);
            setTimeout(() => {
                this.showPrintError(false);
                (<HTMLButtonElement>document.getElementById("printDailyReportDeadline_btn")).disabled = false;
            }, 3000);
        }
    }

    async printAppointments() {
        this.showNotification(false);
        this.showParameterMessage(false);
        this.startRepDeadline((<HTMLInputElement>document.getElementById("startRepDeadline")).value);
        this.endRepDeadline((<HTMLInputElement>document.getElementById("endRepDeadline")).value);

        this.ReportFilter().ClerkID(ko.toJS(this.FristenkontrolleId));

        if (ko.toJS((<HTMLInputElement>document.getElementById("inlineRadio2_app")).checked))
            this.ReportFilter().DoneOnly(true);
        else
            this.ReportFilter().DoneOnly(false);

        try {
            this.showPrintLoader(true);
            let res = (await RNSAPI.reportAppointments(moment(ko.toJS(this.startRepDeadline), "YYYY-MM-DD"), moment(ko.toJS(this.endRepDeadline), "YYYY-MM-DD"), ko.toJS(this.ReportFilter)));
            if (res.Type === "AppointmentReportSuccessful") {
                setTimeout(() => { }, 1000)
                let from = moment(ko.toJS(this.startRepDeadline), "YYYY-MM-DD").format("DD.MM.YYYY");
                let to = moment(ko.toJS(this.endRepDeadline), "YYYY-MM-DD").format("DD.MM.YYYY");

                let element = document.createElement('a');
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(res.Payload.DocumentData);
                } else {
                    element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + res.Payload.DocumentData);
                    element.setAttribute('download', `${'Terminübersicht von ' + from + ' bis ' + to}.docx`);

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                    (<HTMLButtonElement>document.getElementById("printDailyReportAppointment_btn")).disabled = false;
                    $("#reportAppointmentModal").modal("hide");
                    this.showPrintLoader(false);
                }

                $("#reportAppointmentModal").modal("hide");
                this.startRepDeadline('');
                this.endRepDeadline('');
                this.caseRepDeadline('');
                this.ReportFilter().DoneOnly(false);
                this.ReportFilter().CaseID("")
                this.ReportFilter().Locations([]);
            }
            else if(res.Type === "AppointmentReportNoEntries")
            {
                this.showPrintErrorNoAppointments(true);
                this.showPrintLoader(false);
                setTimeout(() => {
                    this.showPrintErrorNoAppointments(false);
                    (<HTMLButtonElement>document.getElementById("printDailyReportDeadline_btn")).disabled = false;
                }, 3000);
            }
        }
        catch {
            this.showPrintLoader(false);
            this.showParameterMessage(true);
        }
    }

    async printDeadline(exact) {
        if (!ko.toJS(this.calledfirst)) {
            this.showNotification(false);
            this.showParameterMessage(false);
            if (exact) {
                this.startRepDeadline((<HTMLInputElement>document.getElementById("startRepDeadline")).value);
                this.endRepDeadline((<HTMLInputElement>document.getElementById("endRepDeadline")).value);
            }

            this.ReportFilter().CaseID(ko.toJS(this.caseRepDeadline));

            this.ReportFilter().ClerkID(ko.toJS(this.ProcessPartnerId));

            if (ko.toJS((<HTMLInputElement>document.getElementById("inlineRadio2")).checked))
                this.ReportFilter().DoneOnly(true);
            else
                this.ReportFilter().DoneOnly(false);

            this.ReportFilter().ClerkID("");
            if (exact)
                this.ReportFilter().ClerkID(ko.toJS(this.FristenkontrolleId));

            try {
                this.showPrintLoader(true);
                let res = (await RNSAPI.reportDeadline(moment(ko.toJS(this.startRepDeadline), "YYYY-MM-DD"), moment(ko.toJS(this.endRepDeadline), "YYYY-MM-DD"), ko.toJS(this.ReportFilter)));
                if (res.Type === "ReportSuccessful") {
                    setTimeout(() => { }, 1000)
                    let from = moment(ko.toJS(this.startRepDeadline), "YYYY-MM-DD").format("DD.MM.YYYY");
                    let to = moment(ko.toJS(this.endRepDeadline), "YYYY-MM-DD").format("DD.MM.YYYY");

                    let element = document.createElement('a');
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(res.Payload.DocumentData);
                    } else {
                        element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + res.Payload.DocumentData);
                        element.setAttribute('download', `${'Fristenübersicht von ' + from + ' bis ' + to}.docx`);

                        element.style.display = 'none';
                        document.body.appendChild(element);

                        element.click();

                        document.body.removeChild(element);
                        (<HTMLButtonElement>document.getElementById("printDailyReportDeadline_btn")).disabled = false;
                        $("#reportDailyDeadlineModal").modal("hide");
                        this.showPrintLoader(false);
                    }

                    $("#reportDeadlineModal").modal("hide");
                    $("#historyOrVerifcationDeadlineModal").modal("hide");
                    this.startRepDeadline('');
                    this.endRepDeadline('');
                    this.caseRepDeadline('');
                    this.ReportFilter().DoneOnly(false);
                    this.ReportFilter().CaseID("")
                    this.ReportFilter().Locations([]);
                }
            }
            catch {
                this.showPrintLoader(false);
                this.showParameterMessage(true);
            }
        }
        else
            this.calledfirst(false);
    }

    async printDeadlineLeadpartner() {
        this.startRepDeadline((<HTMLInputElement>document.getElementById("startRepDeadline")).value);
        this.endRepDeadline((<HTMLInputElement>document.getElementById("endRepDeadline")).value);
        let sb = ko.toJS(this.ProcessPartnerId);
        if (!sb) {
            this.showReportVBError(true);
            return;
        }
        this.showReportVBError(false);
        this.showPrintLoader(true);

        let doneOnly = false;
        if (ko.toJS((<HTMLInputElement>document.getElementById("inlineRadio2_clerk")).checked))
            doneOnly = true;


        await RNSAPI.reportLeadPartnerDeadline(moment(ko.toJS(this.startRepDeadline), "YYYY-MM-DD"), moment(ko.toJS(this.endRepDeadline), "YYYY-MM-DD"), ko.toJS(sb), doneOnly).then((data) => {
            let element = document.createElement('a');
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(data);
            } else {
                element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + data);
                element.setAttribute('download', `${'Fristenübersicht von ' + ko.toJS(this.startRepDeadline) + ' bis ' + ko.toJS(this.endRepDeadline)}.docx`);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
                this.ProcessPartner("");
                this.ProcessPartnerId("");
                this.showPrintLoader(false);
                this.showReportVBError(false);
                $('#reportLeadpartnerDeadlineModal').modal('hide');
            }
        }).catch((error) => {
            this.showPrintLoader(false);
            setTimeout(() => {
                this.showReportError(false);
                this.showReportVBError(false);
            }, 5000);
            this.showReportError(true);
        });
    }

    async download(name: string, DocumentData: any) {

        let element = document.createElement('a');
        let doc = DocumentData;
        let IsRTF = atob(doc.DocumentData.substr(0, 50)).indexOf("rtf") !== -1;
        let type = IsRTF ? "rtf" : doc.OLE2Type.trim().toLowerCase();

        let blob = Utils.base64ToBlob(doc.DocumentData, 'application/octet-stream');

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
        } else {
            element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + doc.DocumentData);
            element.setAttribute('download', `${name.trim()}.${type}`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }

    public async update() {
        let allAppointments;
        let allDeadlines;

        if (ko.toJS(this.currentID) !== '')
            allAppointments = await RNSAPI.getAppointmentsForCase(ko.toJS(this.currentID), moment("2000-01-01", "YYYY-MM-DD").toDate(), moment("2099-12-31", "YYYY-MM-DD").toDate());
        else
            allAppointments = await RNSAPI.getAppointmentsByRange(moment(ko.toJS(this.rStartDate)).toDate(), moment(ko.toJS(this.rEndDate)).add("1", "day").toDate());

        if (ko.toJS(this.currentID) !== '') {
            allDeadlines = await RNSAPI.getDeadlinesForCase(ko.toJS(this.currentID));
            let resCase = (await RNSAPI.getExtendedCase(ko.toJS(this.currentID))).Payload;
            this.instancesInCase(resCase.Instances);
        }
        else {
            allDeadlines = await RNSAPI.getDeadlinesByRange(moment(ko.toJS(this.rStartDate)), moment(ko.toJS(this.rEndDate)));
            this.instancesInCase([]);
        }

        this.updateTodos(allAppointments, allDeadlines);
    }

    newAkteFrist() {
        let obj = {
            SachbearbeiterId: ko.observable(RNSAPI.User().username),
            Sachbearbeiter: ko.observable(null),
            Clerk: ko.observable(null),
            CaseId: ko.observable(null),
            Rubrum: ko.observable("Kein Rubrum vorhanden."),
            DeadlineNumber: ko.observable("-/-"),
            DeadlineText: ko.observable(null),
            CompletedBy: ko.observable(""),
            CompletionDate: ko.observable(""),
            CreatedBy: ko.observable(""),
            StartDate: ko.observable(moment().format("YYYY-MM-DD")),
            PreDeadline: ko.computed(() => {
                if (this.predeadlineNr().startsWith("w")) {
                    let weeks = this.predeadlineNr()[1] + this.predeadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(weeks, "w");
                    return FristDay.format("YYYY-MM-DD");
                }
                else if (this.predeadlineNr().startsWith("m")) {
                    let months = this.predeadlineNr()[1] + this.predeadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(months, "M");
                    return FristDay.format("YYYY-MM-DD");
                }
                else if (this.predeadlineNr().startsWith("j")) {
                    let year = this.predeadlineNr()[1] + this.predeadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(year, "y");
                    return FristDay.format("YYYY-MM-DD");
                }
                else {
                    return moment().format("YYYY-MM-DD");
                }
            }),
            TheDeadline: ko.computed(() => {
                if (this.deadlineNr().startsWith("w")) {
                    let weeks = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(weeks, "w");
                    if (FristDay.day() == 0) {
                        FristDay.add(1, 'day');
                    }
                    else if (FristDay.day() == 6) {
                        FristDay.add(2, 'day');
                    }
                    return FristDay.format("YYYY-MM-DD");
                }
                else if (this.deadlineNr().startsWith("m")) {
                    let months = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(months, "M");
                    if (FristDay.day() == 0) {
                        FristDay.add(1, 'day');
                    }
                    else if (FristDay.day() == 6) {
                        FristDay.add(2, 'day');
                    }
                    return FristDay.format("YYYY-MM-DD");
                }
                else if (this.deadlineNr().startsWith("j")) {
                    let year = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(year, "y");
                    if (FristDay.day() == 0) {
                        FristDay.add(1, 'day');
                    }
                    else if (FristDay.day() == 6) {
                        FristDay.add(2, 'day');
                    }
                    return FristDay.format("YYYY-MM-DD");
                }
                else {
                }
            })
        };
        return ko.observable(obj);
    };

    deadlineNr = ko.observable("");
    predeadlineNr = ko.observable("");
    IsEditMode = ko.observable(false);
    akteFrist = this.newAkteFrist();

    async approvePastDeadline() {
        $("#DeadlineExtension_Body").removeClass("hide");
        $("#DeadlineExtension_Body").addClass("show");
        $("#approvePastExtensionDeadlineBody").removeClass("show");
        $("#approvePastExtensionDeadlineBody").addClass("hide");
        this.pastFristApproved(true);
        this.extendDeadline();
    }

    async verifyRejectDeadline() {
        this.rejectionReason("");
        $("#reasonForRejection_inp").removeClass("has-error");
        $("#rejectVerifyConclusion_Body").removeClass("hide");
        $("#rejectVerifyConclusion_Body").addClass("show");
        $("#verifyConclusion_Body").removeClass("show");
        $("#verifyConclusion_Body").addClass("hide");
    }

    async abortRejectDeadline() {
        $("#verifyConclusion_Body").removeClass("hide");
        $("#verifyConclusion_Body").addClass("show");
        $("#rejectVerifyConclusion_Body").removeClass("show");
        $("#rejectVerifyConclusion_Body").addClass("hide");
    }

    async verifyRejectAppointment() {
        this.rejectionReasonAppointment("");
        $("#reasonForRejectionAppointment_inp").removeClass("has-error");
        $("#rejectVerifyConclusionAppointment_Body").removeClass("hide");
        $("#rejectVerifyConclusionAppointment_Body").addClass("show");
        $("#verifyConclusionAppointment_Body").removeClass("show");
        $("#verifyConclusionAppointment_Body").addClass("hide");
    }

    async abortRejectAppointment() {
        $("#verifyConclusionAppointment_Body").removeClass("hide");
        $("#verifyConclusionAppointment_Body").addClass("show");
        $("#rejectVerifyConclusionAppointment_Body").removeClass("show");
        $("#rejectVerifyConclusionAppointment_Body").addClass("hide");
    }

    async editPastFrist() {
        this.pastFristApproved(false);
        $("#DeadlineExtension_Body").removeClass("hide");
        $("#DeadlineExtension_Body").addClass("show");
        $("#approvePastExtensionDeadlineBody").removeClass("show");
        $("#approvePastExtensionDeadlineBody").addClass("hide");
    }

    async extendDeadline() {
        let deadlineObj = ko.toJS(this.deadlineToBeModified);
        let now = Number(moment(moment.now()).format("YYYYMMDD"));
        let deadlineBeforeToday = Number(moment(moment.now()).format("YYYYMMDD")) > Number(moment(ko.toJS(this.extendDeadlineDate())).format("YYYYMMDD"));
        let predeadBeforeToday = now > Number(moment(ko.toJS(this.extendPreDeadlineDate())).format("YYYYMMDD"));

        let preDeadlines = [];
        for (let i = 0; i < this.extendedPreDeadlines().length; i++) {
            let MultiplePreDeadline = moment($("#Extend_" + i).val(), "YYYY-MM-DD").format("YYYY-MM-DD")
            preDeadlines.push(MultiplePreDeadline);
        }

        if ((deadlineBeforeToday || predeadBeforeToday) && ko.toJS(this.pastFristApproved()) === false) {
            $("#DeadlineExtension_Body").removeClass("show");
            $("#DeadlineExtension_Body").addClass("hide");
            $("#approvePastExtensionDeadlineBody").removeClass("hide");
            $("#approvePastExtensionDeadlineBody").addClass("show");
            return;
        }
        else {
            this.pastFristApproved(false);
        }

        let res = "";
        try {
            if (ko.toJS(this.allExtendsionReasonsLength) !== 0) {
                res = (<HTMLSelectElement>document.getElementById("DeadlineExtensionSelect")).value
            }
            (<HTMLButtonElement>document.getElementById("postExtension")).disabled = true;
            let Export = false;
            if (this.fKAId() !== '' && ko.toJS(this.isEditedObjTransfered()) === true)
                Export = (<HTMLInputElement>document.getElementById('notifyExtention')).checked;
            this.showOutlookLoader(true);

            await RNSAPI.extendDeadline(deadlineObj, ko.toJS(this.extendDeadlineDate()), ko.toJS(this.extendPreDeadlineDate()), ko.toJS(this.extendDeadlineNote()), ko.toJS(this.extendDeadlineClerk()), Export, res, ko.toJS(preDeadlines)).then((response) => {
                let result = response as any;
                RNSAPI.createHistoryEntry(deadlineObj.CaseId, "Frist angelegt");
                if (Export) {
                    this.showOutlookLoader(false);
                    if (result.OutlookExport === "SUCCESS") {
                        this.showError(false);
                        this.showSuccess(true);
                    }
                    else if (result.OutlookExport === "FAILURE") {
                        this.showSuccess(false);
                        this.showError(true);
                    }
                    else {
                        this.showError(false);
                        this.showSuccess(false);
                    }

                    if (result.ExternalExport === "SUCCESS") {
                        this.showExternError(false);
                        this.showExternSuccess(true);
                    }
                    else if (result.ExternalExport === "FAILURE") {
                        this.showExternSuccess(false);
                        this.showExternError(true);
                    }
                    else {
                        this.showExternError(false);
                        this.showExternSuccess(false);
                    }
                }

                setTimeout(() => {
                    this.showOutlookLoader(false);
                    this.showError(false);
                    this.showExternError(false);
                    this.showSuccess(false);
                    this.showExternSuccess(false);
                    this.update();
                    $('#extendDeadlineModal').modal('hide');
                    (<HTMLButtonElement>document.getElementById("postExtension")).disabled = false;
                }, 1000);

            }).catch((error) => {
                (<HTMLButtonElement>document.getElementById("postExtension")).disabled = false;
                alert("Fehler beim Speichern.");
            });
        } catch (e) {
            (<HTMLButtonElement>document.getElementById("postExtension")).disabled = false;
            this.showOutlookLoader(false);
            this.showError(false);
            alert("Fehler beim ..");
            console.log(e);
        }
    }

    async delegateDeadline() {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        let delegateobj = ko.toJS(this.deadlineToBeModified());
        let keys = ['Id', 'AddressTypeId', 'CaseId', 'Clerk', 'CompletedBy', 'CompletionDate', 'CompletionDate2', 'CreatedBy', 'CreationDate', 'Deadline', 'EditedAt', 'EditedFromId', 'ExportToOutlook', 'IMEX', 'IsDoubleCheck', 'IsPasswordProtected', 'NotaryOrLawyer', 'Note', 'Note1', 'Note2', 'Note3', 'Note4', 'OutlookMessageId', 'PreDeadline', 'PrDeadline2', 'ProcessTime', 'RecordId', 'ReplacedWith', 'ReviewAt', 'Rubrum', 'StartDate', 'TypeDescription', 'TypeId'];
        let obj = {};
        for (let key in keys) {
            obj[keys[key]] = delegateobj[keys[key]];
        }
        try {
            (<HTMLButtonElement>document.getElementById("postDelegation")).disabled = true;
            let result = await RNSAPI.delegateDeadline(ko.toJS(obj), ko.toJS(this.newSB));
            if (result.Type == "DelegationSuccessful") {
                $('#delegateDeadlineModal').modal('hide');
                this.update();

            } else {
                alert("Fehler beim Speichern.");
            }
        } catch (e) {
            console.log(e);
            console.log("here is the problem: " + e.responseText);
        }

    }

    async getFKA(caseId) {
        let AccountId = await this.getFristenKalenderForCaseId(caseId);
        let result = (await RNSAPI.getOutlook());
        if (result.Type === "GetAccountsSuccessful") {
            let res = result.Payload.OutlookAccounts;
            if (res.length > 0) {
                let account = "";
                let accountId = "";
                for (let acc of res) {
                    if (acc.OutlookAccountID === AccountId.FKAId) {
                        account = acc.UserLogin;
                        accountId = acc.OutlookAccountID;
                    }
                }
                this.fkAName(account);
                this.fKAId(accountId);
            }
        }
    }

    async getFristenKalenderForCaseId(caseId): Promise<any> {
        let result = { FKA: "", FKAId: "" };
        if (caseId !== "" && caseId !== null) {
            let res = (await RNSAPI.getExtendedCase(caseId)).Payload;
            result.FKA = res.Akte.DeadlineCalendarAccount;
            result.FKAId = res.Akte.DeadlineCalendarAccountId;
            this.attendees(res.Akte.WeitereSachbearbeiterRecords);
        }
        return result;
    }

    async getOutlookAccounts() {
        let result = (await RNSAPI.getOutlook());
        if (result.Type === "GetAccountsSuccessful") {
            let res = result.Payload.OutlookAccounts;
            this.hasFKACount(res.length);
            this.OutlookAccounts(res);
        }
        else
            this.OutlookAccounts([]);
    }

    async pickCase5() {
        let cases = [];
        await RNSAPI.getCasesOverview(0, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });

        this.pickGeneric("Akte", ["caseid", "rubrum", "court_caseid_1", "refId"], ["Akte", "Rubrum", "AZ-Gericht 1. Instanz", "Standort"], cases);
        this.modalHandleSelection((selectedObject) => {
            this.currentRubrum(selectedObject()["rubrum"]);
            this.filter(selectedObject()["caseid"])
        });
        $('#modal').modal('show');
    };

    async pickClerk5() {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Fristenkontrolle wählen", ["Sachbearbeiter"], ["Fristenkontrolle"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            this.sachbearbeiter(selectedObject()["Sachbearbeiter"])
        });
        $('#modal').modal('show');
    };

    async pickVB() {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Verfahrensbearbeiter wählen", ["Sachbearbeiter"], ["Verfahrensbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            this.selectedVB(selectedObject()["Sachbearbeiter_ID"]);
        });
        $('#modal').modal('show');
    }

    async pickClerkInstance(position) {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Fristenkontrolle wählen", ["Sachbearbeiter"], ["Fristenkontrolle"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            if (position === "DeadlineExtension") {
                this.extendDeadlineClerk(selectedObject()["Sachbearbeiter_ID"]);
            }
            if (position === "ProcessPartner") {

                this.ProcessPartner(selectedObject()["Sachbearbeiter"]);
                this.ProcessPartnerId(selectedObject()["Sachbearbeiter_ID"])
            }
            if (position === "Fristenkontrolle") {

                this.Fristenkontrolle(selectedObject()["Sachbearbeiter"]);
                this.FristenkontrolleId(selectedObject()["Sachbearbeiter_ID"])
            }
        });
        $('#modal').modal('show');
    };

    pickclerk = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.newSB(selectedObject()["Sachbearbeiter_ID"]));
        $('#modal').modal('show');
    };

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    TermiCount = ko.observable(0);

    async getFristDeadlineReasons() {
        let res = (await RNSAPI.getFristDeadlineReasons());
        if (res.Type === "GetFristExtensionReasonsSuccessful") {
            this.allDeadlineExtensionReasons([]);
            this.allDeadlineExtensionReasons(res.Payload.DeadlineExtendReasonTypes.map((deadlineExtenstionRea) => {
                return deadlineExtenstionRea;
            }));
            this.allExtendsionReasonsLength(this.allDeadlineExtensionReasons().length)
        }
    }

    async getReferate() {
        let referate = (await RNSAPI.getReferate()).Payload.Units;
        this.hasReferatCount(referate.length);
        let ref = referate.map((r) => {
            r.Checked = false;
            return r;
        });
        this.AllReferate(ref);
    }

    getTypeOfAppointment(Type: string) {
        switch (Type) {
            case "A":
                return "Ausser Haus";
            case "G":
                return "Gericht";
            case "V":
                return "Verkündungstermin";
            case "P":
                return "Privat";
            case "D":
                return "Dauertermin";
            case "N":
                return "Notar";
            case "B":
            default:
                return "Besprechung";
        }
    }

    constructor(params: any) {
        this.isViewerRole(RolesAndRights.isViewerRole());

        let user = RNSAPI.getCurrentLoggedInUser();
        if (user.UseVerificationWorkflow === "1") {
            this.hasVerificationRights(true);
        }

        moment.locale("de");
        this.hasButton(true);
        if (params.id !== undefined) {
            this.currentID(ko.toJS(params.id));
            this.filter(ko.toJS(params.id));
            this.hasButton(false);
            Postbox.publisher().publish("", "checkReferate");
        }
        else {
            this.rStartDate(ko.toJS(moment().format("YYYY-MM-DD")));
            this.rEndDate(ko.toJS(moment().format("YYYY-MM-DD")));
        }

        this.getFristDeadlineReasons();

        this.getAllUsers();

        this.update();

        Postbox.publisher().subscribe(() => {
            this.update();
        }, 'updateTFW')

        this.filteredTodos = ko.computed({
            owner: this,
            read: () => {
                let filter = this.filter();
                let sachbearbeiter = this.sachbearbeiter();
                let vb = this.selectedVB();
                let filters = [];

                if (this.values().complete()) {
                    filters.push('complete');
                } else {
                    filters.indexOf('complete') !== -1 ? filters.splice(filters.indexOf('complete'), 1) : null;
                }

                if (this.values().yellow()) {
                    filters.push('yellow');
                } else {
                    filters.indexOf('yellow') !== -1 ? filters.splice(filters.indexOf('yellow'), 1) : null;
                }
                if (this.values().red()) {
                    filters.push('red');
                } else {
                    filters.indexOf('red') !== -1 ? filters.splice(filters.indexOf('red'), 1) : null;
                }

                if (this.values().bell()) {
                    filters.push('bell');
                } else {
                    filters.indexOf('bell') !== -1 ? filters.splice(filters.indexOf('bell'), 1) : null;
                }

                const filterTypes = Object.keys(this.types()).filter(item => this.types()[item]());

                let items = this.todos();

                if (ko.toJS(this.selectedFilterStandort()) !== "")
                    items = items.filter(item => this.filterWithStandort(item));

                if (ko.toJS(this.selectedFilterAccount()) !== "")
                    items = items.filter(item => this.filterWithFKA(item));
                if (ko.toJS(this.selectedInstance()) !== "")
                    items = items.filter(item => this.filterWithInstance(item));

                if (filters.length) {
                    items = items.filter(item => this.filterWithStars(item, filters));
                }
                if (filterTypes.length) {
                    items = items.filter(item => this.filterWithType(item, filterTypes));
                }
                if (filter) {
                    items = items.filter(item => item['number'] && item['number'].indexOf(filter) !== -1);
                }
                if (sachbearbeiter) {
                    items = items.filter(item => item['SB'] && item['SB'].includes(sachbearbeiter));
                }

                if (vb) {
                    items = items.filter(item => this.filterWithVB(item, vb));
                }

                if (ko.toJS(this.currentID) !== '' && ko.toJS(this.currentID) !== "undefined") {
                    this.getNextWV(items);

                }
                return items;
            }
        });

        this.rStartDate.subscribe(() => this.update());
        this.rEndDate.subscribe(() => this.update());

        Postbox.publisher().subscribe((start: string, end: string) => {
            this.startRepDeadline(start);
            this.endRepDeadline(end);
        }, 'UpdateRepDeadlines');

        this.getReferate();

        this.getOutlookAccounts();
    }
}


let nameTable: { [key: string]: string; } = {
    "appointment": "Termin",
    "deadline": "Frist",
    "predeadline": "Vorfrist",
    "inbox": "Posteingang",
    "resubmission": "Wiedervorlage",
    "ecase": "EAkte"
};

var html = fs.readFileSync(__dirname + '/tfw.html', 'utf8');

ko.components.register("tfw-view", {
    viewModel: TfwViewModel,
    template: html
});