import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import Navigo = require("navigo");
import { Postbox } from "../postbox/postbox";
import { RolesAndRights } from "../../helpers/RolesAndRights";

declare var $: any;


export class HeaderViewModel {

    collapsedSidebar = ko.observable(false);
    isLicenseExpired = ko.observable<boolean>(false);
    isViewerRole = ko.observable<boolean>(false);
    timerPaused = ko.observable(false);
    trigger = ko.observable(false);
    currentUser = ko.observable(null);
    router = new Navigo(null, true);
    assets = '/assets/';

    logout() {
        Utils.eraseCookie('access_token');
        RNSAPI.sessionLogout();
        this.router.navigate('/login');
    }

    gotoSettings(path) {
        this.router.navigate(path);
        $("html, body").animate({ scrollTop: 0 }, "fast");
    }

    opennewTab() {
        window.open("/#/cases");
    }

    public getVM = () => this;

    private setUser() {
        this.currentUser(RNSAPI.getCurrentLoggedInUser());
    }

    constructor() {
        this.isViewerRole(RolesAndRights.isViewerRole());
        this.isLicenseExpired(RolesAndRights.isLicenseExpired());
        Postbox.publisher().subscribe((response) => {
            this.collapsedSidebar(response);
        }, "CollapsedSidebar");

        Postbox.publisher().subscribe(() => {
            this.isLicenseExpired(RolesAndRights.isLicenseExpired());
            this.setUser();
        }, "setLoggedInUserHeader");

        if (location.hash !== "#/login")
            this.setUser();   
    }
}

var html = fs.readFileSync(__dirname + '/header.html', 'utf8');

ko.components.register("header-view", {
    viewModel: HeaderViewModel,
    template: html
});