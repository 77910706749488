import * as fs from "fs";
import * as ko from "knockout";
import Navigo = require("navigo");
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import { Postbox } from "../postbox/postbox";

export class LoginViewModel {
    router = new Navigo(null, true);
    token = ko.observable("");
    password = ko.observable('');
    username = ko.observable('')
    courtId = ko.observable('')
    logins = ko.observable({
        username: ko.observable(''),
        courtId: ko.observable(''),
        password: ko.observable('')
    });

    checkLogin = ko.observable(false);

    resetPasswordForm = ko.observable(false);
    resetPasswordFormSite = ko.observable(0);
    resetPasswordData = ko.observable({
        LawFirmId: ko.observable(''),
        Username: ko.observable(''),
        Password: ko.observable(''),
        PasswordRetry: ko.observable(''),
        Mail: ko.observable(''),
        Code: ko.observable('')
    });
    resetPasswordErrorMessage = ko.observable("");
    processResetPasswordForm = ko.observable(false);

    async nextStepResetPasswordForm() {
        this.processResetPasswordForm(true);
        this.resetPasswordErrorMessage("");
        switch(this.resetPasswordFormSite()) {
            case 0: 
                if(this.resetPasswordData().LawFirmId() !== "" && this.resetPasswordData().Username() !== "") {
                    this.resetPasswordFormSite(this.resetPasswordFormSite() + 1);
                } else {
                    this.resetPasswordErrorMessage('Bitte geben Sie Ihre Kundennummer und Ihr Benutzerkürzel ein.');
                }
                break;
            case 1:
                const passwordMail = await this.checkResetPasswordMail();
                if(!passwordMail) { 
                    this.resetPasswordErrorMessage('Die eingegebene E-Mail Adresse konnte nicht bestätigt werden.');
                } else {
                    this.resetPasswordFormSite(this.resetPasswordFormSite() + 1);
                }
                break;
            case 2:
                const mailCode = await this.checkMailCode();
                if(!mailCode) { 
                    this.resetPasswordErrorMessage('Der eingegebene Bestätigungscode ist falsch.');
                } else {
                    this.resetPasswordFormSite(this.resetPasswordFormSite() + 1);
                }
               break;
            case 3:
                if(this.resetPasswordData().Password() !== this.resetPasswordData().PasswordRetry()) {
                    this.resetPasswordErrorMessage("Die eingegebenen Passwörter stimmen nicht überein.");
                } else {
                    if(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/.test(ko.toJS(this.resetPasswordData().Password).toString())) {
                        const changePassword = await this.changePassword();
                        if(!changePassword) { // !
                            this.resetPasswordErrorMessage('Das Passwort konnte nicht zurückgesetzt werden');
                        } else {
                            this.resetPasswordFormSite(this.resetPasswordFormSite() + 1);
                        }
                    } else {
                        this.resetPasswordErrorMessage("Bitte mindestens 8 Zeichen und eine Kombination aus Kleinbuchstaben, Großbuchstaben, Ziffern und Sonderzeichen verwenden.");
                    }
                }
                break;
        }
        this.processResetPasswordForm(false);
    }

    async changePassword(): Promise<boolean> {
        let passwordChanged = false;
         await RNSAPI.resetAccountPassword({ LawFirmId: this.resetPasswordData().LawFirmId(), Username: this.resetPasswordData().Username(), Password: this.resetPasswordData().Password() }).then(data => {
             passwordChanged = true;
         }).catch(error => {
             passwordChanged = false;
         });
        return passwordChanged;
    }
   
    async checkResetPasswordMail(): Promise<boolean> {
        let verifiedMail = false;
         await RNSAPI.verifyAccountMail({ LawFirmId: this.resetPasswordData().LawFirmId(), Username: this.resetPasswordData().Username(), Mail: this.resetPasswordData().Mail() }).then(data => {
             verifiedMail = true;
         }).catch(error => {
             verifiedMail = false;
         });
        return verifiedMail;
    }

    async checkMailCode(): Promise<boolean> {
        let verifiedCode = false;
         await RNSAPI.verifyAccountMailCode({ LawFirmId: this.resetPasswordData().LawFirmId(), Username: this.resetPasswordData().Username(), Code: this.resetPasswordData().Code() }).then(data => {
             verifiedCode = true;
         }).catch(error => {
             verifiedCode = false;
         });
        return verifiedCode;
    }

    async login() {
        if (!this.logins().password() || !this.logins().username() || !this.logins().courtId()) {
            $("#pc_validation_message").show();
            $("#pc_message").html("Es wurden nicht alle Pflichtfelder ausgefüllt.");
            return;
        }

        this.checkLogin(true);

        await RNSAPI.authUser({ LawFirmId: this.logins().courtId(), Username: this.logins().username(), Password: this.logins().password()}).then(data => {
            RNSAPI.login(data["AccessToken"]);
            Postbox.publisher().publish("", "setLoggedInUserHeader");
            Postbox.publisher().publish("", "setLoggedInUserLayout");
            MainViewModel.RoutingTable.showTfwView();
        }).catch(error => {
            if(error.status === 400) {
                this.checkLogin(false);
                document.getElementById('loginloader').setAttribute("style", "display: none;");
                $("#pc_validation_message").show();
                $("#pc_message").html("Bitte geben Sie die korrekten Zugangsdaten Ihres Soldan-Fristenmodul-Kontos an.");
            }
        });
    }

    gotoRegister() {
        this.router.navigate('/register');
    }

    gotoLogin() {
        this.resetPasswordForm(false);
    }

    resetPassword() {
        this.resetPasswordFormSite(0);
        this.resetPasswordForm(true);
        this.resetPasswordData().Code("");
        this.resetPasswordData().LawFirmId("");
        this.resetPasswordData().Username("");
        this.resetPasswordData().Mail("");
    }

    constructor() {
        if (typeof localStorage !== 'undefined') {
            try {
                if (localStorage.getItem('LawFirmInfo')) {
                    let lawFirmInfo = JSON.parse(localStorage.getItem('LawFirmInfo'))
                    this.logins().username(lawFirmInfo['ShorthandSymbol'])
                    this.logins().courtId(lawFirmInfo['LawFirmId'])
                }
            } catch (e) { }
        }

        RNSAPI.AuthenticationFailed = 0;
    }
}

var html = fs.readFileSync(__dirname + '/login.html', 'utf8');

ko.components.register("login-view", {
    viewModel: LoginViewModel,
    template: html
});