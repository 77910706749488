import { RNSAPI } from "../../api";
import { Utils } from '../../utils';
import * as fs from 'fs';
import * as ko from "knockout";
import * as model from './model';
import * as moment from 'moment';
import '../dialog/message';
import '../dialog/deadlineDialog';
import '../address/address_form'
import Navigo = require("navigo");
import { Postbox } from "../postbox/postbox";

export class NewViewModel {
    data: ko.Observable<Data | undefined> = ko.observable<Data | undefined>(undefined);
    rmvPersonText = ko.observable<string>('');
    infoMessage = ko.observable('');
    infoTitle = ko.observable('');
    modialAutoHide = ko.observable(false);    
    rmvPerson = ko.observable('');
    rmvType = ko.observable('');    
    isOpeningGegner = ko.observable(false);
    isOpeningMandanten = ko.observable(false);
    addresses = ko.observableArray([]);
    isLoaded = ko.observable(false);
    isLoadedGer = ko.observable(false);
    caseId: string;    
    mandantIndex = ko.observable(0);
    firstName = ko.observable();    
    info: ko.Observable<any> = ko.observable();
    extendedInfo: ko.Observable<any> = ko.observable();
    emptyPersonType = ko.observable(true);
    FKA = ko.observable('');
    FKAId = ko.observable('');
    infData = this.infoModel();
    ad = this.createPersonObservable();    
    public getVM = () => this;
    userType = ko.observable("");
    caseData: ko.ObservableArray<any> = ko.observableArray();
    caseAddresses = ko.observableArray();
    akte: ko.Observable<any> = ko.observable();
    currentView = ko.observable('beleiligte');
    rachts = ko.observableArray([]);
    rachtsPerson = ko.observableArray([]);
    briefkopf = ko.observableArray([]);
    allInstances = ko.observableArray([]);
    currentInstance = ko.observable(this.instanceType());
    currentInstanceSBs = ko.observableArray([]);
    currentCourtId = ko.observable("");
    showNoSBInstanceError = ko.observable(false);
    removeInstanceId = ko.observable("");
    removeInstanceName = ko.observable("");
    weitereSBCount = ko.observable(0);
    weitereSB = ko.observableArray([]);
    allReferate = ko.observableArray([]);
    GerAdressen = ko.observableArray([]);
    filter = ko.observable('');
    modalSelectedObject = ko.observable(null);
    initialfocusinput = true;
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    isEditMode = ko.observable(false);
    caseEntries: ko.ObservableArray<any>;
    isDocumentsEmpty: ko.Computed<boolean>;    
    deleteEntryid = ko.observable("");
    basicEntry = this.newBasicEntry();
    errorMessage = ko.observable("");
    editDocumentTitle = ko.observable("");
    editDocumentWorkflow = ko.observable("");
    editDocumentSachbearbeiter = ko.observable("");
    editDocumentId = ko.observable("");
    wfNames = {
        "PE": "Posteingang",
        "RA": "Bearbeitung Rechtsanwalt",
        "B": "Bearbeitung Büro",
        "ZU": "Unterschriftsmappe",
        "V": "Postausgang",
        "PA": "Gesendet",
        "E": "Erledigt",
    }
    workflows = [
        {
            "Id": "PE",
            "Description": "Posteingang",
            "Followers": ["B", "RA"]
        }, {
            "Id": "RA",
            "Description": "Bearbeitung Rechtsanwalt",
            "Followers": ["B", "V"]
        }, {
            "Id": "B",
            "Description": "Bearbeitung Büro",
            "Followers": ["ZU", "RA"]
        }, {
            "Id": "ZU",
            "Description": "Unterschriftsmappe",
            "Followers": ["B", "V"]
        }, {
            "Id": "V",
            "Description": "Postausgang",
            "Followers": ["PA"]
        }, {
            "Id": "PA",
            "Description": "Gesendet",
            "Followers": ["E"]
        }
        , {
            "Id": "E",
            "Description": "Erledigt",
            "Followers": []
        }
    ]

    newBasicEntry() {
        let obj = {
            Id: ko.observable(null),
            CaseId: ko.observable(""),
            CurrentCRTVersion: ko.observable(" "),
            DocType: ko.observable(0),
            Documents: ko.observableArray([]),
            LawyerId: ko.observable(RNSAPI.User() ? RNSAPI.User().username : "GR"),
            MimeType: ko.observable("application/pdf"),
            Note1: ko.observable(""),
            Recipient: ko.observable(""),
            Subject: ko.observable(""),
            imex: ko.observable("A"),
            isScanned: ko.observable("0"),
            isVerschluss: ko.observable(false),
            WorkflowId: ko.observable("")
        };

        return ko.observable(obj);
    };

    weitereSBType() {
        let obj = {
            ID: ko.observable(''),
            Name: ko.observable('')
        }
        return obj
    }

    newDeadline = async () => {
        $('#CaseNewDeadlineDialogExt').modal('show');
    }

    newAppointment = async () => {
        this.currentView("ftw");
        Postbox.publisher().publish(this.caseId, "FillNewAppointmentDataWithCaseID");
        $("#AppointmentTFWmodal").modal("show");
    }

    removeSB(sb) {
        let sbid = this.weitereSB()[sb].ID;
        let maydelete = true;
        let inst = ko.toJS(this.allInstances);
        
        for (let i = 0; i < inst.length; i++) {
            let instsb = inst[i].Clerks;
            for (let j = 0; j < instsb.length; j++) {
                if (instsb[j].ClerkId === sbid) {
                    maydelete = false;
                    break;
                }
            }
        }

        if (maydelete) {
            this.weitereSB.splice(sb, 1);
            this.weitereSBCount(this.weitereSB().length);
            this.save(null);
        }
        else {
            this.infoTitle('')
            this.infoTitle('Verfahrensbearbeiter zugeordnet')
            this.infoMessage('')
            this.infoMessage('Der ausgewählte Verfahrensbearbeiter ist noch einer Instanz zugeordnet. Entfernen Sie diesen und versuchen Sie es erneut.')
        }
    }

    pickSachbearbeiterForIndex = async (a) => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Verfahrensbearbeiter wählen", ["Sachbearbeiter"], ["Verfahrensbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            let sb = this.weitereSBType();
            sb.ID = selectedObject()["Sachbearbeiter_ID"];
            sb.Name = selectedObject()["Sachbearbeiter"];
            this.weitereSB.push(sb);
            this.weitereSBCount(this.weitereSB().length);
            this.save(null);
        });
        $('#ExtCasemodal').modal('show');
    };

    instanceType() {
        let obj = {
            InstanceType: ko.observable(''),
            ExternalProgramId: ko.observable(''),
            ExternalUniqueId: ko.observable(''),
            CourtId: ko.observable(''),
            CourtCase: ko.observable(''),
            CourtCaseValue: ko.observable(''),
            Clerks: []
        }
        return obj
    }
    instanceClerkType() {
        let obj = {
            instanceId: ko.observable(0),
            ClerkId: ko.observable(''),
            ClerkName: ko.observable(''),
            actionHandlers: ko.observableArray([])
        }
        return obj
    }

    selectFirstCaseData() {
        this.userType("Mandant");
        if (this.caseAddresses[0].length === 0) {
            this.emptyPersonType(true);
        }
        else {
            this.emptyPersonType(false);
            this.changePerson(this.caseAddresses[0][0], 0, 'mandant', 0);
        }
    }

    selectFirstCaseDataGegner() {
        this.userType("Gegner");
        if (this.caseAddresses[1].length === 0) {
            this.emptyPersonType(true);
        }
        else {
            this.emptyPersonType(false);
            this.changePerson(this.caseAddresses[1][0], 0, 'gegner', 0);
        }
    }

    showInfoMessage() {
        setTimeout(() => {
            this.infoMessage('');
            this.modialAutoHide(true);
            $("html, body").animate({ scrollTop: 0 }, "fast");
        }, 4000)
    }

    openEditAddressModal(component, type, data) {
        this.changePerson(data, null, null, null);
        this.ad(data);
        this.ad().Sonstige1 = ko.toJS(component);
        Postbox.publisher().publish(this.ad(), "updateAddressModal");
        $('#allAddressModal').modal('show');
    }

    changePerson(data, index, type, rootIndex) {
        if (!data) {
            return;
        }

        this.ad(data);

        Postbox.publisher().publish(this.ad(), "updateAddressModal");
    }

    changeButtonText(data, index, type, rootIndex) {
        if (!data) {
            return;
        }
    }

    newPersonObservable() {

        let obj = {
            FirstName: ko.observable(''),
            Address: ko.observable(''),
            Adress2: ko.observable(''),
            Adress2CityName: ko.observable(''),
            Adress2CountryID: ko.observable(''),
            Adress2PLZ: ko.observable(''),
            Adresszusatz: ko.observable(''),
            AnredeBriefkopf: ko.observable(''),
            AnredeDirekt: ko.observable(''),
            Arbeitgeber1: ko.observable(''),
            Arbeitgeber2: ko.observable(''),
            Arbeitgeber3: ko.observable(''),
            Bank: ko.observable(''),
            BankId: ko.observable(''),
            BirthName: ko.observable(''),
            BirthDate: ko.observable(''),
            CentralPhone: ko.observable(''),
            CityName: ko.observable(''),
            CountryID: ko.observable(''),
            CountryName: ko.observable(''),
            DebitorenKontoNummer: ko.observable(''),
            DisplayName: ko.observable(''),
            Ehepartner: ko.observable(''),
            EmailAddress: ko.observable(''),
            Fax: ko.observable(''),
            GesetzlicherVertreter1: ko.observable(''),
            GesetzlicherVertreter1Id: ko.observable(''),
            GesetzlicherVertreter2: ko.observable(''),
            GesetzlicherVertreter2Id: ko.observable(''),
            GesetzlicherVertreter3: ko.observable(''),
            GesetzlicherVertreter3Id: ko.observable(''),
            GesetzlicherVertreter4: ko.observable(''),
            GesetzlicherVertreter4Id: ko.observable(''),
            IBAN: ko.observable(''),
            Imex: ko.observable(''),
            KennzeichenMB: ko.observable(''),
            KontoNummer: ko.observable(''),
            KreditorenKontoNummer: ko.observable(''),
            MBType: ko.observable(''),
            MobilePhone: ko.observable('+49'),
            Name1: ko.observable(''),
            Name2: ko.observable(''),
            Name3: ko.observable(''),
            Note1: ko.observable(''),
            Note2: ko.observable(''),
            Note3: ko.observable(''),
            Note4: ko.observable(''),
            Note5: ko.observable(''),
            PLZPostfach: ko.observable(''),
            PersonalausweisNr: ko.observable(''),
            Phone1: ko.observable('+49'),
            Phone2: ko.observable(''),
            Phone3: ko.observable(''),
            Postfach: ko.observable(''),
            Rechtsform: ko.observable(''),
            Sonstige1: ko.observable(''),
            Sonstige2: ko.observable(''),
            SpezielleAnrede2: ko.observable(''),
            URL: ko.observable(''),
            ZipCode: ko.observable(''),
            Keyword: ko.computed<string>(String)
        };

        obj["Keyword"] = ko.computed(function () {
            return obj.DisplayName().replace(/\s/g, "") + obj.FirstName().replace(/\s/g, "");
        });

        return ko.observable(obj);
    };

    async loadData(id) {
        const caseData1: any = (await RNSAPI.getExtendedCase(id)).Payload;
        this.caseAddresses = caseData1.Addresses;
        setTimeout(() => {
            this.changeButtonText(this.caseAddresses[0][0], 0, 'mandant', 0);
        }, 500);
        location.reload();
    }

    createNewPerson() {
        Postbox.publisher().publish(this.userType(), "clearAddressModal");
        $('#allAddressModal').modal('show');
    }

    async pickPerson(type) {
        let addrType = 2;
        let headline = "Mandanten auswählen";
        if (type === "gegner") {
            headline = "Gegner auswählen";
            addrType = 3;
        }
        else if (type === "sonstige")
            headline = "Sonstige auswählen"

        this.isOpeningGegner(true);
        this.isOpeningMandanten(true);
        this.isLoaded(false);
        let addresses = [];
        await RNSAPI.getAdressPagin(addrType, "").then(async (addressesinp) => {
            let addr = addressesinp as any;
            addresses = addr;

            let ad = addresses.map((o) => {
                o.actionHandlers = [{
                    icon: "user-check",
                    name: 'Adresse ausw\u00e4hlen',
                    action: async () => {
                        let personFromDb = o;
                        let obj = this.newPersonObservable();
                        for (let key in personFromDb) {
                            if (obj[key] === undefined) {
                                obj[key] = personFromDb[key];
                            } else if (key !== "Keyword") {
                                obj[key](personFromDb[key]);
                            } else {
                                obj["Keyword"] = ko.observable(personFromDb["Keyword"]);
                            }
                        }
                        $('#pickPersonsModal').modal('hide');
                        this.joinPerson(type, obj["Keyword"]);
                    }
                }];
                return o;
            });

            this.addresses(addresses);
            this.isLoaded(true);

            $('#pickPersonsModal').modal('show');
            this.isOpeningGegner(false);
            this.isOpeningMandanten(false);
        }).catch((error) => {
            console.log(error);
            addresses = [];
        });
    };

    unJoinPerson(component, data, type) {
        this.rmvPerson(data.Keyword);
        this.rmvPersonText(data.FirstName + " " + data.Name1 + " " + data.Name2 + " " + data.Name3);
        this.rmvType(component);
        $('#RemovePerson').modal('show');
    }

    async actuallyUnjoin() {
        let result = await RNSAPI.unjoinPerson(ko.toJS(this.rmvPerson), this.caseId, ko.toJS(this.rmvType))
        $('#RemovePerson').modal('hide');
        this.messageModalWithReload();
    }

    async joinPerson(type, personKeyWord) {
        let result = await RNSAPI.joinPerson(personKeyWord, this.caseId, type)
        console.log(result)
        this.messageModalWithReload();
    }

    messageModalWithReload() {
        this.infoMessage('Daten erfolgreich erfasst.');
        this.infoTitle('Erledigt');
        this.modialAutoHide(true);
        this.showInfoMessage();
        setTimeout(() => {
            this.loadData(this.caseId);
        }, 3000)
    }

    async pickCourtForInstance() {
        $('#gerichtsmodal').modal('show');
        if (this.GerAdressen.length === 0) {
            let gerichte = [];
            await RNSAPI.getAdressPagin(8, "").then((addresses) => {
                let addr = addresses as any;
                gerichte = addr;
            }).catch((error) => {
                console.log(error);
                gerichte = [];
            });

            this.GerAdressen(gerichte);
            this.isLoadedGer(true);
            let AllAddr = this.GerAdressen();
            Postbox.publisher().subscribe((Filter) => {
                this.GerAdressen(Filter);
                this.isLoadedGer(true);
            }, "ApplyFilter");
            document.getElementById('inputGer-filter').oninput = function (f) {
                let theFilter = (<HTMLInputElement>document.getElementById('inputGer-filter')).value;
                let Filtered = [];
                if (theFilter.length > 2) {
                    for (let i = 0; i < AllAddr.length; i++) {
                        if ((AllAddr[i].Name + AllAddr[i].City + AllAddr[i].Keyword).toString().toLowerCase().includes(theFilter.toLowerCase())) {
                            Filtered.push(AllAddr[i])
                        }
                    }
                    Postbox.publisher().publish(Filtered, "ApplyFilter");
                }
                else {
                    Postbox.publisher().publish(AllAddr, "ApplyFilter");
                }

            }
        }
        this.modalHandleSelection((selectedObject) => { this.currentInstance().CourtId = selectedObject()["Keyword"]; this.currentCourtId(selectedObject()["Keyword"]) });
    }

    focusinput() {
        if (this.initialfocusinput) {
            document.getElementById("inputGer-filter").focus();
            this.initialfocusinput = false;
        }
    }

    modalPick() {
        if (this.modalHandleSelection !== null) this.modalHandleSelection()(this.modalSelectedObject);
        $('#gerichtsmodal').modal('hide');
        $('#pickPersonsModal').modal('hide');
    };

    modalSelect = (obj, event) => {

        if ($(event.target.parentElement).hasClass("active")) {
            this.modalPick();
        }
        else {
            $("#modalTable tr").removeClass("active");
            $(event.target.parentElement).addClass("active");
            this.modalSelectedObject(obj);
        }
    }

    async pickGVertreter(type: string) {
        let addresses = (await RNSAPI.getPersonsForScope(type)).Payload.Addresses;
        this.pickGeneric("Gesetzlicher Vertreter", ["FirstName", "Name1", "CityName"], ["Vorname", "Nachname", "Ort"], addresses);
        this.modalHandleSelection((selectedObject) => {
            Postbox.publisher().publish(selectedObject()["Keyword"], "ChooseGVertreter");
        });
        $('#ExtCasemodal').modal('show');
    }

    createPersonObservable() {
        let obj = {

            FirstName: ko.observable(''),
            Address: ko.observable(''),
            Adress2: ko.observable(''),
            Adress2CityName: ko.observable(''),
            Adress2CountryID: ko.observable(''),
            Adress2PLZ: ko.observable(''),
            Adresszusatz: ko.observable(''),
            AnredeBriefkopf: ko.observable(''),
            AnredeDirekt: ko.observable(''),
            Arbeitgeber1: ko.observable(''),
            Arbeitgeber2: ko.observable(''),
            Arbeitgeber3: ko.observable(''),
            Bank: ko.observable(''),
            BankId: ko.observable(''),
            BirthDate: ko.observable(''),
            BirthName: ko.observable(''),
            CentralPhone: ko.observable(''),
            CityName: ko.observable(''),
            CountryID: ko.observable(''),
            CountryName: ko.observable(''),
            DebitorenKontoNummer: ko.observable(''),
            DisplayName: ko.observable(''),
            Ehepartner: ko.observable(''),
            EmailAddress: ko.observable(''),
            Fax: ko.observable(''),
            GesetzlicherVertreter1: ko.observable(''),
            GesetzlicherVertreter1Id: ko.observable(''),
            GesetzlicherVertreter2: ko.observable(''),
            GesetzlicherVertreter2Id: ko.observable(''),
            GesetzlicherVertreter3: ko.observable(''),
            GesetzlicherVertreter3Id: ko.observable(''),
            GesetzlicherVertreter4: ko.observable(''),
            GesetzlicherVertreter4Id: ko.observable(''),
            IBAN: ko.observable(''),
            Imex: ko.observable(''),
            KennzeichenMB: ko.observable(''),
            KontoNummer: ko.observable(''),
            KreditorenKontoNummer: ko.observable(''),
            MBType: ko.observable(''),
            MobilePhone: ko.observable('+49'),
            Name1: ko.observable(''),
            Name2: ko.observable(''),
            Name3: ko.observable(''),
            Note1: ko.observable(''),
            Note2: ko.observable(''),
            Note3: ko.observable(''),
            Note4: ko.observable(''),
            Note5: ko.observable(''),
            PLZPostfach: ko.observable(''),
            PersonalausweisNr: ko.observable(''),
            Phone1: ko.observable('+49'),
            Phone2: ko.observable(''),
            Phone3: ko.observable(''),
            Postfach: ko.observable(''),
            Rechtsform: ko.observable(''),
            Sonstige1: ko.observable(''),
            Sonstige2: ko.observable(''),
            SpezielleAnrede2: ko.observable(''),
            URL: ko.observable(''),
            ZipCode: ko.observable(''),
            Keyword: ko.observable(''),
            RSVersId: ko.observable(''),
            RSVersNr: ko.observable(''),
            RSSelbstbehalt: ko.observable(''),
        };

        return ko.observable(obj);
    };

    infoModel() {
        let obj = {
            E_PostJa: ko.observable("false"),
            E_PostNein: ko.observable("false"),
            Fallkonstellation: ko.observable(""),
            MEK: ko.observable(""),
            MandatSonstiges: ko.observable(""),
            Notiz_Mandantenbegehren: ko.observable(""),
            Notiz_Sachverhalt: ko.observable(""),
            Notizen: ko.observable(""),
            mandat: ko.observable(""),
            FKA: ko.observable(""),
            FKAId: ko.observable("")
        }
        return ko.observable(obj);
    };

    async initialize(CaseId: string) {
        await this.getData(CaseId).then(data => {
            this.data(data);
        });
    }

    async getReferateCode(value, type) {
        if (this.allReferate().length > 0) {
            const key = type === 'Name' ? 'Id' : 'Name'
            let referate = (await RNSAPI.getReferate()).Payload.Units;
            const found = referate.filter(item => item[key] === value)[0];
            if (found !== undefined)
                return found[type]
            else
                return "";
        }
        else
            return "";
    }

    converToCurrency(value) {
        return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    }

    converFromCurrency(value) {
        let val: any = this.removeDotsFromNumber(value).replace(',', '.');
        return val;
    }

    removeDotsFromNumber(str: string) {
        return str.replace(/\./g, '');
    }

    pickInstance = async () => {
        if (ko.toJS(this.weitereSB).length === 0) {
            this.infoTitle('Info')
            this.infoMessage('');
            this.infoMessage('Bitte hinterlegen Sie weitere Verfahrensbearbeiter in der Akte um eine Instanz anlegen zu können.');
            this.currentView('weitereSB')
            return;
        }
        Postbox.publisher().publish("", "ResetDialogFilterOption");
        let instances = [];
        await RNSAPI.getInstances().then((response) => {
            let res = response as any;
            instances = res;

        }).catch((error) => { });

        this.pickGeneric("Instanz wählen", ["Name"], ["Instanz"], instances);
        this.modalHandleSelection((selectedObject) => {
            let instanceObj = this.instanceType();
            instanceObj.InstanceType = selectedObject()["Name"];
            instanceObj.Clerks = [];
            instanceObj.CourtCaseValue("");
            this.currentInstance(instanceObj);
            this.allInstances.push(this.currentInstance());
            this.currentInstanceSBs(instanceObj.Clerks);
            this.currentCourtId(instanceObj.CourtId());
            $("#editInstanceModal").modal("show");
        });
        $('#ExtCasemodal').modal('show');
    };

    async addSBtoInstance() {
        Postbox.publisher().publish("", "ResetDialogFilterOption");
        let sachbearbeiter = this.weitereSB();
        this.pickGeneric("Verfahrensarbeiter wählen", ["Name"], ["Verfahrensarbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            let newSBinstance = this.instanceClerkType();
            newSBinstance.ClerkId = selectedObject()["ID"];
            newSBinstance.ClerkName = selectedObject()["Name"];
            newSBinstance.actionHandlers([]);
            newSBinstance.actionHandlers(ko.toJS([
                {
                    icon: "trash-alt",
                    name: "Verfahrensbearbeiter entfernen",
                    action: () => {
                        let sbsInInstance = this.currentInstanceSBs();
                        sbsInInstance = sbsInInstance.filter(a => a.ClerkId !== newSBinstance.ClerkId);
                        this.currentInstance().Clerks = sbsInInstance;
                        this.currentInstanceSBs(sbsInInstance);
                    }
                }]));

            this.currentInstanceSBs.push(newSBinstance);
            this.showNoSBInstanceError(false);
        });
        $('#ExtCasemodal').modal('show');
    }

    async saveCurrentInsance(data) {
        if (ko.toJS(this.currentCourtId) === "") {
            $("#courtIdModal").addClass('has-error');
            return;
        }
        else
            $("#courtIdModal").removeClass('has-error');
        if (ko.toJS(this.currentInstanceSBs).length === 0) {
            this.showNoSBInstanceError(true);
            return;
        }
        else {
            this.showNoSBInstanceError(false);
        }

        $("#editInstanceModal").modal("hide");
        this.saveAndRedirect(data);
    }

    async save(data) {
        if (!Utils.checkMultipleErrors(["Rubrum", "SachbearbeiterId"], this.akte(), "", [Utils.checkString])) return;

        let d: Data = this.data();
        let obj = this.akte();
        d.caseData.Akte.Rubrum = obj["Rubrum"];
        d.caseData.Akte.Wegen = obj["Wegen"];
        d.caseData.Akte.Referat = await this.getReferateCode(ko.toJS(obj["Referat"]), 'Id') || obj["Referat"];
        d.caseData.Akte.SachbearbeiterId = $("#SachbearbeiterId").val().toString();
        d.caseData.Akte.AnlageDatum = moment(ko.toJS(obj["AnlageDatum"])).format("DD.MM.YYYY");
        if (ko.toJS(d.caseData.Akte.AnlageDatum) === "Invalid date") {
            d.caseData.Akte.AnlageDatum = null;
        }

        let insta = this.allInstances().map((a) => {
            if(a.InstanceType === "1. Instanz")
                d.caseData.Akte.AktenZeichenGericht1 = a.CourtCase;
            if(a.InstanceType === "2. Instanz")
                d.caseData.Akte.AktenZeichenGericht2 = a.CourtCase;
            if(a.InstanceType === "3. Instanz")
                d.caseData.Akte.AktenZeichenGericht3 = a.CourtCase;
            a.CourtCaseValue = ko.toJS(a.CourtCaseValue)
            if (a.CourtCaseValue === "")
                a.CourtCaseValue = "0";
            a.CourtCaseValue = ko.toJS(this.converFromCurrency(a.CourtCaseValue.toString()));
            return a;
        })

        d.caseData.Instances = insta;

        d.caseData.Akte.MandantCaseID = obj["MandantCaseID"];
        d.caseData.Akte.KorrAnwaltCaseID = obj["KorrAnwaltCaseID"];

        d.caseData.Akte.DeadlineCalendarAccount = ko.toJS(this.info).FKA;
        d.caseData.Akte.DeadlineCalendarAccountId = ko.toJS(this.info).FKAId;

        d.caseData.Akte.weitereSachbearbeiter

        d.caseData.Akte.WeitereSachbearbeiterRecords = [];
        for (let sb of ko.toJS(this.weitereSB)) {
            let t = { SachbearbeitertId: sb.ID }
            d.caseData.Akte.WeitereSachbearbeiterRecords.push(t);
        }

        $(".newcase input, .newcase select, .newcase textarea").each(function () {
            d.caseData.ExtendedCase.Infos[$(this).attr('id')] = $(this).attr('type') === 'checkbox' ? $(this).is(':checked') : $(this).val()
        })

        try {
            const res = await RNSAPI.saveExtendedCase(d.caseData);

            if (res.Type !== "CaseUpdateSuccessful") {
                console.log('extended case updated succ')
                this.infoTitle('Fehler')
                this.infoMessage('');
                this.infoMessage('Datensatz konnte nicht erfolgreich gespeichert werden.');
            } else {
                this.infoTitle('');
                this.infoTitle('Erledigt')
                this.infoMessage('');
                this.infoMessage('Daten erfolgreich erfasst.');
                this.modialAutoHide(true)
                this.loadData(this.caseId);
            }

        } catch (e) {
            let error = Utils.handleError(e);
            this.infoMessage('');
            this.infoMessage(error['code'] + ': ' + error['message']);
        }

    }

    async saveAndRedirect(data) {
        this.save(data);
    }

    async getData(caseId: string): Promise<Data> {
        const caseData: any = (await RNSAPI.getExtendedCase(caseId)).Payload;
        let infd = {};

        infd["FKA"] = ko.observable(caseData.Akte.DeadlineCalendarAccount);
        infd["FKAId"] = ko.observable(caseData.Akte.DeadlineCalendarAccountId);

        if(caseData.Akte.DeadlineCalendarAccount === null ||caseData.Akte.DeadlineCalendarAccount === undefined)
            infd["FKA"] = ko.observable('');
        if(caseData.Akte.DeadlineCalendarAccountId === null ||caseData.Akte.DeadlineCalendarAccountId === undefined)
            infd["FKAId"] = ko.observable('');

        this.info(infd);
        var x = ko.toJS(this.mandantIndex);

        this.caseData = caseData;
        this.caseAddresses = caseData.Addresses;
        this.extendedInfo = caseData;
        setTimeout(() => {
            this.changePerson(this.caseAddresses[0][0], 0, 'mandant', 0)
        }, 1000);
        var obj = {};

        for (let key in caseData.Akte) {
            obj[key] = ko.observable(caseData.Akte[key]);
        }

        if (obj['Referat']()) {
            const value = await this.getReferateCode(obj['Referat'](), 'Name')
            if (value) {
                obj['Referat'](value)
            }
        }
        this.akte(obj);
        this.akte().WertGericht1 = Utils.formatCurrency(caseData.Akte.WertGericht1 || '0,00');
        this.akte().WertGericht2 = Utils.formatCurrency(caseData.Akte.WertGericht2 || '0,00');
        this.akte().WertGericht3 = Utils.formatCurrency(caseData.Akte.WertGericht3 || '0,00');

        var ad = {};
        for (let key in caseData.Addresses[0]) {
            ad[key] = ko.observable(caseData.Addresses[key]);
        }

        this.selectFirstCaseData();

        this.akte().AnlageDatum = ko.observable(moment(caseData.Akte.AnlageDatum).format("YYYY-MM-DD"));
        this.akte().AblageDatum = ko.observable(moment(caseData.Akte.AblageDatum).format("YYYY-MM-DD"));
        this.akte().deadlineDateResubmission = ko.observable(moment(caseData.Akte.FristDatum).format("YYYY-MM-DD"));
        this.akte().deadlineDate1 = ko.observable(moment(caseData.Akte.MahnDatum).format("YYYY-MM-DD"));

        for (let sbs of ko.toJS(this.akte().WeitereSachbearbeiterRecords)) {
            let sb = this.weitereSBType();
            sb.ID = sbs.SachbearbeitertId;
            sb.Name = sbs.FullName;
            this.weitereSB.push(sb);
        }

        let inst = ko.toJS(caseData.Instances).map((a) => {
            a.actionHandlers = [];
            return a;
        });

        inst = inst.map((o) => {
            o.actionHandlers.push({
                icon: "pencil-alt",
                name: "Bearbeiten",
                action: () => {
                    o.Clerks = o.Clerks.map((u) => {
                        if (!u.ClerkName)
                            u.ClerkName = u.Name;
                        u.actionHandlers = [
                            {
                                icon: "trash-alt",
                                name: "Verfahrensbearbeiter entfernen",
                                action: () => {
                                    let sbsInInstance = ko.toJS(this.currentInstanceSBs());
                                    sbsInInstance = sbsInInstance.filter(a => a.ClerkId !== u.ClerkId);
                                    this.currentInstance().Clerks = sbsInInstance;
                                    this.currentInstanceSBs(sbsInInstance);
                                }
                            }];
                        return u;
                    });
                    this.currentInstance(o);
                    this.currentInstanceSBs(o.Clerks);
                    this.currentCourtId(o.CourtId);
                    $("#editInstanceModal").modal("show");
                }
            });
            o.FirstVB =""
            if(o.Clerks.length > 0)
                o.FirstVB = o.Clerks[0].Name;
            
            o.CourtCaseValue = this.converToCurrency(o.CourtCaseValue.toString());
            return o;
        });

        this.allInstances(inst);

        this.weitereSBCount(ko.toJS(this.weitereSB).length);

        this.loadEntries();

        return {
            caseId: caseId,
            layout: undefined,
            caseData: caseData,
            extended: undefined,
            selectedRechtsGebiet: undefined,
            selectedBereich: undefined,
            currentTab: ko.observable("IdentifikationsTab"),
        };
    }

    async actuallyRemoveInstance() {
        let removeInstance = ko.toJS(this.allInstances());
        removeInstance = removeInstance.filter(a => a.InstanceId !== ko.toJS(this.removeInstanceId));
        this.allInstances(removeInstance);
        $("#removeInstance").modal("hide");
        this.save(null);
    }

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    pickReferat = async () => {
        const referate = ko.toJS(this.allReferate);
        this.pickGeneric("Standort", ["Name", "Id"], ["Name", "Id"], referate);
        this.modalHandleSelection((selectedObject) => this.akte().Referat(selectedObject()["Name"]));
        $('#ExtCasemodal').modal('show');
    };

    async getReferate() {
        const referate = (await RNSAPI.getReferate()).Payload.Units;
        if (referate.length === 0) {
            $('#Referat').attr("disabled", "true");
        }
        this.allReferate(referate);
    }

    async loadEntries() {
        try {
            let entries = (await RNSAPI.getCaseEntriesForId(this.caseId)).Payload.Records
                // .filter((entry) => {
                //     return entry.MimeType.indexOf("application/vnd.renostar.xmp") === -1;
                // })
                .reverse()
                .map((entry) => {
                    entry.DocumentDate = moment(entry.DocumentDate || entry.RecordDate).format('DD.MM.YYYY');
                    entry.WorkflowIdName= this.wfNames[entry.WorkflowId];
                    entry.SBName = entry.LawyerId;
                    entry.RecordDate = moment(entry.RecordDate).format('DD.MM.YYYY');
                    if (entry.Subject === "")
                        entry.Subject = entry.DocDBIds[0];
                    entry.actionHandlers = [
                        {
                            'icon': 'eye',
                            'name': 'Ansehen',
                            'action': () => {
                                this.showPDF(entry.Id);                                                                                             

                                $('#DocumentViewerModal').modal('show');
                            }
                        },
                        {
                            'icon': 'pencil-alt',
                            'name': 'Bearbeiten',
                            'action': () => {
                                console.log(entry);
                                this.editDocumentTitle(entry.Subject);
                                this.editDocumentWorkflow(entry.WorkflowId);
                                this.editDocumentSachbearbeiter(entry.LawyerId);
                                this.editDocumentId(entry.Id);
                                $('#editDocument').modal('show');
                            }
                        },
                        {
                            'icon': 'cloud-download-alt',
                            'name': 'Download',
                            'action': () => {
                                if (entry.Id === '') {
                                    entry.Id = entry.DocDBIds[0];
                                    entry.SearchDate = entry.DocumentDate;
                                }
                                this.download(entry.Subject, entry.Id, entry.SearchDate);
                            }
                        },
                        {
                            'icon': 'trash-alt',
                            'name': 'Löschen',
                            'action': () => {
                                this.remove(entry.Id);
                            }
                        }
                    ];

                    return entry;
                });                
            this.caseEntries(entries);
        } catch (e) {
            console.log(e);
        }
    }

    async remove(id: string) {
        $("#DeleteEntry").modal('show');
        this.deleteEntryid(id);
    }

    async actuallydelete() {
        await RNSAPI.deleteRecord(ko.toJS(this.deleteEntryid));
        await this.loadEntries();
        $("#DeleteEntry").modal('hide');
    }

    async download(name: string, id: string, date?: Date) {
        let element = document.createElement('a');
        let doc;
        if (date)
            doc = (await RNSAPI.getDocumentDataByName(id, date)).Payload.Document;
        else
            doc = (await RNSAPI.getDocumentData(id)).Payload.Document;
        //server side bug in the ActiveX Component => OleType is doc instead of rtf

        let IsRTF = atob(doc.DocumentData.substr(0, 50)).indexOf("rtf") !== -1;
        let type = IsRTF ? "rtf" : doc.OLEType.trim().toLowerCase();

        let blob = Utils.base64ToBlob(doc.DocumentData, 'application/octet-stream');

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
        } else {
            element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + doc.DocumentData);
            element.setAttribute('download', `${name.trim()}.${type}`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }

    async showPDF(recordId) {
        let doc = (await RNSAPI.getDocumentData(recordId)).Payload.Document;
        let { DocumentData } = doc;
        let blob = Utils.base64ToBlob(DocumentData, "application/pdf");
        var file = window.URL.createObjectURL(blob);
        $('#pdf-view').attr('src', file)
    }
    
    async createEntry() {
        this.resetUploadDocument();
        $('#uploadDocument').modal('show');
    }

    resetUploadDocument() {
        this.basicEntry().Subject('');
        $('#upload-file-info').text('');
    }

    async postDokumentData() {
        let selectedFile = (document.getElementById('customFile') as any).files[0];
        let formData = new FormData();
        if (selectedFile === undefined) {
            this.errorMessage("Bitte geben Sie eine Datei an.");
            return;
        } else {
            if (selectedFile.size / 1024 / 1024 > 10) {
                this.errorMessage("Die Ausgewählte Datei ist zu groß.");
                return;
            } 
            else {
                if (selectedFile.type !== "application/pdf") {
                    this.errorMessage("Das Ausgewählte Dateiformat wird nicht unterstützt.");
                    return;
                }
                if (this.basicEntry().Subject()) {
                    formData.append("Document", selectedFile);
                    await this.createDokumenEntryRef(formData, selectedFile.name.split('.').pop());
                } else {
                    this.errorMessage("Es wurden nicht alle Pflichtfelder ausgefüllt.");
                }
            }

        }
    }

    async createDokumenEntryRef(docData: FormData, ending: string): Promise<void> {
        docData.append("CaseId", this.caseId);
        docData.append("OleType", ending.toUpperCase());
        docData.append("OLE2Type", ending.toUpperCase());
        docData.append("LawyerId", RNSAPI.User().username);
        docData.append("Note1", "");
        docData.append("Recipient", "");
        docData.append("Subject", this.basicEntry().Subject());
        docData.append("WorkflowId", this.basicEntry().WorkflowId());

        await RNSAPI.createCaseFileEntry(docData).then((response) => {
            $('#uploadDocument').modal('hide');
            this.loadEntries();
        }).catch((error) => {
            this.errorMessage("Die Datei konnte nicht hochgeladen werden.");
        });
    }

    async editDokumentData(){

        await RNSAPI.editRecord(ko.toJS(this.editDocumentId), ko.toJS(this.editDocumentWorkflow), ko.toJS(this.editDocumentSachbearbeiter), ko.toJS(this.editDocumentTitle)).then((response)=> {
            this.editDocumentTitle("");
            this.editDocumentWorkflow("");
            this.editDocumentSachbearbeiter("");
            this.editDocumentId("");
            $('#editDocument').modal('hide');
            this.loadEntries();
        }).catch((error) =>{
            //Error
        });
        
    }

    pickRechtsform = async () => {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        let x = [];
        let y = [];
        let z = [];
        let a = [];
        let b = [];
        let c = [];
        for (let i = 0; i < rechtsformen.length; i++) {
            if (rechtsformen[i].NameID != "") {
                if (rechtsformen[i].NameID === "Herr" || rechtsformen[i].NameID === "Frau" || rechtsformen[i].NameID === "Divers") {
                    a.push(rechtsformen[i].NameID);
                    b.push(rechtsformen[i].Briefanrede);
                    c.push(rechtsformen[i].Anrede);
                }
                else {
                    x.push(rechtsformen[i].NameID);
                    y.push(rechtsformen[i].Briefanrede)
                    z.push(rechtsformen[i].Anrede)
                }
            }

        }
        this.rachts(x);
        this.rachtsPerson(a);
        this.briefkopf(rechtsformen);
    };

    pickFKA = async () => {
        let FKA = (await RNSAPI.getOutlook()).Payload.OutlookAccounts;
        this.pickGeneric("Fristenkalender wählen", ["UserLogin"], ["Fristenkalender"], FKA);
        this.modalHandleSelection((selectedObject) => {
            this.info().FKA(ko.toJS(selectedObject()["UserLogin"]))
            this.info().FKAId(ko.toJS(selectedObject()["OutlookAccountID"]))
        });
        $('#ExtCasemodal').modal('show');
    };

    pickSachbearbeiter = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Billing Partner wählen", ["Sachbearbeiter"], ["Billing Partner"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.akte().SachbearbeiterId(selectedObject()["Sachbearbeiter_ID"]));
        $('#ExtCasemodal').modal('show');
    };

    pickSachbearbeiterForDocument = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter wählen", ["Sachbearbeiter"], ["Billing Partner"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.editDocumentSachbearbeiter(selectedObject()["Sachbearbeiter_ID"]));
        $('#ExtCasemodal').modal('show');
    };

    constructor(params) {
        $("html, body").animate({ scrollTop: 0 }, "fast");
        this.initialize(params.caseId);
        this.caseEntries = ko.observableArray([]);
        this.isDocumentsEmpty = ko.computed({
            owner: this,
            read: () => {
                return this.caseEntries().length === 0;
            }
        });
        this.caseId = params.caseId;
        this.pickRechtsform();

        ko.bindingHandlers.bindHTML = {
            init: function () {
                return { controlsDescendantBindings: true };
            },
            update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var value = ko.utils.unwrapObservable(valueAccessor());
                ko.applyBindingsToNode(element, { html: value }, viewModel);
                ko.applyBindingsToDescendants(bindingContext, element);
            }
        }
        const cView = sessionStorage.getItem('currentView')
        if (cView) {
            this.currentView(cView);
        }

        this.getReferate();

        Postbox.publisher().subscribe((inf) => {
            this.infoMessage(inf.infMessage);
            this.infoTitle(inf.infTitle);
            this.modialAutoHide(inf.infshow);
            this.showInfoMessage();
            setTimeout(() => {
                this.loadData(this.caseId);
            }, 3000)

        }, 'showInfoCase');

        Postbox.publisher().subscribe((type: string) => {
            this.pickGVertreter(type);
        }, 'showGV');

        Postbox.publisher().subscribe(() => { this.getReferate() }, "checkReferate")
    }
}

interface Data {
    caseId: string,
    layout: any,
    caseData: any,
    selectedRechtsGebiet: ko.Observable<string>,
    selectedBereich: ko.Observable<string>,
    extended: model.Extended,
    currentTab: ko.Observable<string>,
}

var html = fs.readFileSync(__dirname + '/new.html', 'utf8');

ko.components.register("new-view", {
    viewModel: NewViewModel,
    template: html
});