import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment";
import { Utils } from "../../utils";
import { RNSAPI } from "../../api";
import { Postbox } from '../postbox/postbox'
import './dialog';
import './message';
import { MainViewModel } from "../../main";

export class appointmentDialogViewModel {
    givenId = "modal";
    times = [];
    postSuccess = ko.observable(false);
    infoMessage = ko.observable('');
    infoTitle = ko.observable('');
    modalAutoHide = ko.observable(false);
    IsEditMode = ko.observable(false);
    startDate = ko.observable(moment().format("YYYY-MM-DD"));
    startTime = ko.observable("00:00");
    endDate = ko.observable(moment().format("YYYY-MM-DD"));
    endTime = ko.observable("00:00");
    Subject = ko.observable("");
    appointmentType = ko.observable("G");
    CaseId = ko.observable("");
    SbId = ko.observable("");
    Place = ko.observable("");
    Note = ko.observable("");
    AppointmentId = ko.observable("");
    AppointmentInstance = ko.observable(null);
    ReasonForRejection = ko.observable("");
    hideoncreate = ko.observable();
    AllCases = ko.observableArray();
    AllSB = ko.observableArray();
    wholeday = ko.observable(false);
    currentRubrum = ko.observable("");
    disableSaveButton = ko.observable(false);
    showReasonOfRejection = ko.observable(false);
    isEditedObjTransfered = ko.observable(true);
    InstanceInCase = ko.observableArray([]);
    showErrorOfInstances = ko.observable(false);
    hasInstance = ko.observable(false);

    parseDate = (dateStr: string) => moment.utc(dateStr, "DD.MM.YYYY", true);

    viewTypes = [
        { name: "Tagesansicht", view: "daily-view" },
        { name: "Wochenansicht", view: "weekly-view" },
    ];

    dateWarnningError = ko.observable(null);
    selectedType = ko.observable(this.viewTypes[0].view);
    showOutlookLoader = ko.observable(false);
    showError = ko.observable(false);
    showSuccess = ko.observable(false);
    parentVM: any;
    public Appoint: any;
    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    }
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);

    attendeesType() {
        let obj = {
            EMailAddress: ko.observable(''),
            IsRequired: true
        }
        return obj
    }
    attendees = ko.observableArray([]);
    fKAId = ko.observable('');
    fkAName = ko.observable('');
    modalHandleSelection = ko.observable();

    changeDateFormat = (date: string) => {
        var d = date.split("-");
        return `${d[2]}.${d[1]}.${d[0]}`;
    };

    parseTime = (timeStr: string) => moment(timeStr, "HH:mm");

    constructDate = (date: string, time: string, timeType?: string) => {
        let parsedDate = this.parseDate(date);

        if (this.selectedType() === "Frist") {
            return parsedDate.toISOString();
        } else {
            if (this.wholeday()) {
                let parsedTime = this.parseTime(timeType === 'start' ? '00:00' : '23:59');
                parsedDate.add(parsedTime.hours(), "hours");
                parsedDate.add(parsedTime.minutes(), "minutes");
                return parsedDate.toISOString();
            }

            let parsedTime = this.parseTime(time);
            let hour = parsedTime.hour();
            parsedDate.add(parsedTime.hours(), "hours");
            parsedDate.add(parsedTime.minutes(), "minutes");
            return parsedDate.toISOString();
        }
    };

    checkDate = (str: string) => { return { validation: moment.utc(str).isValid(), message: ' ist Pflichtfeld' } };
    matchDate = (start: string, end: string) => moment(start).isSame(end)

    checkTime = (str: string) => this.parseTime(str).isValid();

    newBasicAppointment() {
        let obj = {
            AppointmentSubject: ko.observable(null),
            AppointmentDate: ko.computed(() =>
                this.constructDate(this.startDate().toString(), this.startTime(), 'start')
            ),
            StartDate: ko.computed(() =>
                this.constructDate(this.startDate().toString(), this.startTime(), 'start')
            ),
            EndDate: ko.computed(() =>
                this.constructDate(this.endDate().toString(), this.endTime(), 'end')
            ),
            AppointmentType: ko.observable(""),
            AppointmentNote: ko.observable(""),
            SachbearbeiterId: ko.observable(""), //RNSAPI.User().username
            Sachbearbeiter: ko.observable(null),
            CaseId: ko.observable(""),
            Completed: ko.observable(false),
            CreationDate: ko.observable(null),
            CreatorId: ko.observable(""),
            EditedAtDate: ko.observable(null),
            EditorId: ko.observable(""),
            Id: ko.observable(""),
            IsDeleted: ko.observable(false),
            IsRecurringAppointment: ko.observable(false),
            LawyerId: ko.observable(RNSAPI.User() ? RNSAPI.User().username : "GR"),
            MeetingPlace: ko.observable(""),
            MeetingPlaceStreet: ko.observable(""),
            Moved: ko.observable(""),
            RoomID: ko.observable(""),
            InstanceId: ko.observable("")
        };
        obj["AppointmentName"] = ko.computed(() => obj.AppointmentSubject());

        return ko.observable(obj);
    }

    basicAppointment = this.newBasicAppointment();

    fillCaseIdData(data, caseId) {
        this.fillNewAppointmentData(data);
        this.CaseId(caseId);        
        this.getFKA();
    }

    fillNewAppointmentData(data) {
        this.isEditedObjTransfered(true);
        if (data) {
            this.basicAppointment = this.newBasicAppointment();
            this.startDate(moment(data().startDate).format("YYYY-MM-DD"));
            this.endDate(moment(data().startDate).format("YYYY-MM-DD"));
            this.startTime(data().time || "00:00");
            this.Subject("");
            this.fkAName("");
            this.fKAId("");
            this.AppointmentId("");
            this.Place("");
            this.Note("");
            this.appointmentType("G");
            this.CaseId("");
            this.SbId(RNSAPI.User().username)
            this.currentRubrum("");
            this.IsEditMode(false);
            this.InstanceInCase([]);
            this.AppointmentInstance(null);
            setTimeout(() => {
                this.changedStartTime()
            }, 10);
        }
    }
    async fillAppointmentData(id) {
        try {
            let result = await RNSAPI.getAppointment(id);
            this.hasInstance(false);
            if (result.Type === "AppointmentFound") {
                this.IsEditMode(true);
                let appointment = result.Payload.Appointment;
                
                this.basicAppointment = this.newBasicAppointment();
                let obj = this.basicAppointment();

                let excluding = [
                    "AppointmentDate",
                    "AppointmentName",
                    "StartDate",
                    "EndDate",
                    "isRecurringAppointment",
                ];
                for (let key in appointment) {
                    if (obj[key] === undefined) {
                        obj[key] = appointment[key];
                    } else if (excluding.indexOf(key) === -1) {
                        obj[key](appointment[key]);
                    }
                }

                this.basicAppointment(obj);
                this.CaseId(ko.toJS(this.basicAppointment().CaseId));
                this.Subject(ko.toJS(this.basicAppointment().AppointmentSubject));
                this.AppointmentId(ko.toJS(this.basicAppointment().Id));
                this.Place(ko.toJS(this.basicAppointment().MeetingPlace));
                this.Note(ko.toJS(this.basicAppointment().AppointmentNote));
                this.appointmentType(ko.toJS(this.basicAppointment().AppointmentType));
                this.AppointmentId(ko.toJS(this.basicAppointment().Id));
                await this.getFKA();
                this.SbId(ko.toJS(this.basicAppointment().LawyerId));
                let Rubrum = "";

                if (ko.toJS(this.basicAppointment().CaseId) !== "") {
                    let resCase = (await RNSAPI.getExtendedCase(ko.toJS(this.basicAppointment().CaseId))).Payload;
                    let hasInstance = ko.toJS(this.basicAppointment().InstanceId);

                    if(hasInstance === null)
                        this.hasInstance(false);
                    else
                        this.hasInstance(true);
                    
                    this.setInstance(resCase.Instances);
                    this.AppointmentInstance(ko.toJS(this.basicAppointment().InstanceId));
                    Rubrum = resCase.Akte.Rubrum;
                }

                this.showReasonOfRejection(false);
                this.ReasonForRejection("");
                if(ko.toJS(obj["HasToBeVerified"]))
                {
                    if(ko.toJS(obj["VerifiedOn"]) !== null && ko.toJS(obj["IsVerificationAccepted"]) === false)
                    {
                        this.showReasonOfRejection(true);
                        this.ReasonForRejection(ko.toJS(obj["ReasonForRejection"]));
                    }
                }

                this.isEditedObjTransfered(ko.toJS(obj["WasExportedToOutlook"]));

                let momentStart = moment
                    .utc(appointment.StartDate)

                let momentStartTime = moment
                    .utc(appointment.StartDate)

                let momentEndTime = moment
                    .utc(appointment.EndDate)

                this.startDate(momentStart.format("YYYY-MM-DD"));
                this.endDate(momentEndTime.format("YYYY-MM-DD"));
                this.startTime(momentStartTime.format("HH:mm"));
                this.endTime(momentEndTime.format("HH:mm"));

                if(ko.toJS(this.startTime) === "00:00" && ko.toJS(this.endTime) === "23:59" || ko.toJS(this.appointmentType) === "W"){
                    this.wholeday(true);
                    if(ko.toJS(this.appointmentType) !== 'W')
                        (<HTMLInputElement>document.getElementById("customCheck1")).checked = true;
                }
                else
                {
                    this.wholeday(false);
                    (<HTMLInputElement>document.getElementById("customCheck1")).checked = false;
                }

            } else {
                alert("Termin nicht gefunden.");
            }
        } catch (e) {
            alert("Verbindung zum Server abgebrochen.");
            console.log(e);
        }
    }
    async createAppointment() {
        try {
            this.postSuccess(false);
            (<HTMLButtonElement>document.getElementById("save-appointment-btn")).disabled = true;
            let Export = false;
            let Notify = false;
            let att = [];
            if (ko.toJS(this.fKAId) !== '') {
                Export = (<HTMLInputElement>document.getElementById('OutlookExport_inp')).checked;
                Notify = (<HTMLInputElement>document.getElementById('notifyClerkAppointment')).checked;
                if (Export) {
                    this.showOutlookLoader(true);
                    att = [];
                    for (let d of ko.toJS(this.attendees)) {
                        let ob = this.attendeesType();
                        ob.EMailAddress = d.Email;
                        ob.IsRequired = true;
                        att.push(ob);
                    }
                }
            }
            else
                (<HTMLButtonElement>document.getElementById("save-appointment-btn")).disabled = false;

            this.basicAppointment().AppointmentSubject(ko.toJS(this.Subject));
            this.basicAppointment().Id(ko.toJS(this.AppointmentId))
            this.basicAppointment().MeetingPlace(ko.toJS(this.Place));
            this.basicAppointment().AppointmentNote(ko.toJS(this.Note));
            this.basicAppointment().AppointmentType(ko.toJS(this.appointmentType));
            this.basicAppointment().CaseId(ko.toJS(this.CaseId));
            this.basicAppointment().InstanceId(ko.toJS(this.AppointmentInstance));
            this.basicAppointment().LawyerId(ko.toJS(this.SbId));
            await RNSAPI.createAppointment(this.basicAppointment(), Export, ko.toJS(this.fKAId), att, Notify).then((response) => {
                if(ko.toJS(this.showOutlookLoader()))
                    this.showSuccess(true);
                this.showOutlookLoader(false);
                
                Postbox.publisher().publish(true, "AppointmentCreatedSuccessful");
                if (window.location.href.endsWith('tfw') || window.location.href.includes('new'))
                    Postbox.publisher().publish("", "updateTFW")
                this.basicAppointment().AppointmentSubject("");
                this.Subject("");

                this.fkAName("");
                this.AppointmentId("");
                this.Place("");
                this.Note("");
                this.CaseId("");
                this.SbId(RNSAPI.User().username);
                this.currentRubrum("");
                this.appointmentType("G");
                this.basicAppointment().CaseId("");
                this.basicAppointment().MeetingPlace("");
                this.basicAppointment().AppointmentNote("");
                this.basicAppointment().InstanceId("");
                this.InstanceInCase([]);
                Postbox.publisher().publish('Hello', 'updateDashboard')
                setTimeout(() => {
                    $("#" + this.givenId).modal("hide");
                    $("#EndDateWarning").modal("hide");
                    this.infoTitle('Erledigt')
                    this.infoMessage('Speicherung im Soldan Fristenmodul erfolgreich.')
                    this.modalAutoHide(true);
                    this.postSuccess(true);
                    this.showSuccess(false);
                    this.fKAId("");
                    (<HTMLButtonElement>document.getElementById("save-appointment-btn")).disabled = false;
                }, 2000);
            }).catch((error) => {
                console.log(error)
                alert("Fehler beim Anlegen des Termins.");
            });
        } catch (e) {
            console.log(e);
            alert(
                "Fehler beim Anlegen" + e.responseText ? ": " + e.responseText : ""
            );
        }
    }
    hideContinueWithWarnningModal() {
        $("#EndDateWarning").modal("hide");
        $("#" + this.givenId).modal("show");
    }

    async continueWithWarnning() {

        this.createAppointment();
    }

    async appointmentModalPostData() {
        $(".form-group").each(function () {
            $(this).removeClass("has-error");
        });

        if (
            !Utils.checkErrors(["startDate", "endDate"], this, "", [
                Utils.checkString,
                this.checkDate,
            ])
        ) {
            alert("ungültiges Datum");
            return;
        }

        if (
            this.selectedType() === "Termin" &&
            !Utils.checkErrors(["startTime", "endTime"], this, "", [
                Utils.checkString,
                this.checkTime,
            ])
        ) {
            alert("ungültige Zeit");
            return;
        }

        if (!this.CaseId()) {
            alert("Bitte hinterlegen Sie eine Akte");
            return;
        }

        if (!this.Subject()) {
            alert("Bitte hinterlegen Sie einen Betreff");
            return;
        }

        if (!this.SbId()) {
            alert("Bitte hinterlegen Sie einen Fristenkontrolleur");
            return;
        }

        this.startDate(this.changeDateFormat(this.startDate()));
        this.endDate(this.changeDateFormat(this.endDate()));

        if (
            new Date(this.basicAppointment().StartDate()) >
            new Date(this.basicAppointment().EndDate())
        ) {
            this.startDate(this.parseDate(this.startDate()).format("YYYY-MM-DD"));
            this.endDate(this.parseDate(this.endDate()).format("YYYY-MM-DD"));
            alert("Das Enddatum liegt vor dem Startdatum");
            return;
        }

        if (this.IsEditMode()) {
            try {
                (<HTMLButtonElement>document.getElementById("save-appointment-btn")).disabled = true;
                let exp = false;
                if (ko.toJS(this.fKAId) !== '' && ko.toJS(this.isEditedObjTransfered)) {
                    let Export = (<HTMLInputElement>document.getElementById('OutlookExport_inp')).checked;
                    let Notify = (<HTMLInputElement>document.getElementById('notifyClerkAppointment')).checked;
                    if (Export) {
                        exp = Export;
                        this.showOutlookLoader(true);
                    }
                }

                this.basicAppointment().AppointmentSubject(ko.toJS(this.Subject));
                this.basicAppointment().MeetingPlace(ko.toJS(this.Place));
                this.basicAppointment().AppointmentNote(ko.toJS(this.Note));
                this.basicAppointment().Id(ko.toJS(this.AppointmentId));
                if(ko.toJS(this.hasInstance) === false)
                    this.basicAppointment().InstanceId(ko.toJS(this.AppointmentInstance));

                await RNSAPI.updateAppointment(this.basicAppointment(), exp, ko.toJS(this.fKAId)).then((response) => {
                    (<HTMLButtonElement>document.getElementById("save-appointment-btn")).disabled = false;
                    this.Subject("");
                    this.Place("");
                    this.Note("");
                    this.AppointmentId("");
                    this.basicAppointment().AppointmentSubject("");
                    this.basicAppointment().CaseId("");
                    this.basicAppointment().MeetingPlace("");
                    this.showOutlookLoader(false);
                    this.IsEditMode(false);
                    Postbox.publisher().publish(true, "AppointmentCreatedSuccessful");
                    $("#" + this.givenId).modal("hide");
                    setTimeout(() => {
                        Postbox.publisher().publish('edited', 'updatedAppointment');
                    }, 1000);
                    Postbox.publisher().publish('edit', 'updateDashboard');
                    if (window.location.href.endsWith('tfw') || window.location.href.includes('new'))
                        Postbox.publisher().publish("", "updateTFW")
                }).catch((error) => {
                    console.log(error)
                    alert("Fehler beim Speichern.");
                });

                this.IsEditMode(false);
            } catch (e) {
                console.log(e);
                alert("Fehler beim Speichern: " + e.responseText);
            }
        } else {
            try {

                let endDate = (this.wholeday() || ko.toJS(this.appointmentType) === 'W') ? moment(this.basicAppointment().EndDate()).subtract(1, 'day').format('YYYY-MM-DD') :
                    moment(this.basicAppointment().EndDate()).format('YYYY-MM-DD');

                let currentDate = moment().format('YYYY-MM-DD');

                if (moment(endDate).isBefore(currentDate)) {
                    this.closeModal();
                    $("#" + 'EndDateWarning').modal("show");
                    return
                }

                this.createAppointment()
            } catch (e) {
                console.log(e);
                alert(
                    "Fehler beim Anlegen" + e.responseText ? ": " + e.responseText : ""
                );
            }
        }
    }

    adjustDigits(val: number, places: number) {
        var str = String(val);
        for (var i = 0; i < places - String(val).length; i++) {
            str = "0" + str;
        }
        return str;
    }

    async pickCase() {
        let cases = [];
        await RNSAPI.getCasesOverview(0, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });
        this.pickGeneric("Akte", ["caseid", "rubrum", "court_caseid_1", "refId"], ["Akte", "Rubrum", "AZ-Gericht 1. Instanz", "Standort"], cases);
        this.modalHandleSelection(async (selectedObject) => {
            this.CaseId(selectedObject()["caseid"]);
            let resCase = (await RNSAPI.getExtendedCase(ko.toJS(this.CaseId))).Payload;
            this.setInstance(resCase.Instances);
            this.getFKA();
        });
        $("#appointModalmodal").modal("show");
    }

    navigateToCase(){
        $("#" + this.givenId).modal("hide");
        MainViewModel.RoutingTable.showNewView({ caseId: ko.toJS(this.CaseId) });
    }

    setInstance(instance){
        this.InstanceInCase(instance);
        if (instance.length > 0) {
            this.AppointmentInstance(instance[instance.length - 1].InstanceId);
            this.showErrorOfInstances(false);
            (<HTMLButtonElement>document.getElementById("save-appointment-btn")).disabled = false;
        }
        else {
            (<HTMLButtonElement>document.getElementById("save-appointment-btn")).disabled = true;
            this.showErrorOfInstances(true);
        }
    }

    pickSachbearbeiter = async () => {
        Postbox.publisher().publish("", "ResetDialogFilterOption");
        let sachbearbeiter = (
            await RNSAPI.getSachbearbeiter()
        ).Payload.Clerks.filter((s) => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric(
            "Sachbearbeiter",
            ["Sachbearbeiter"],
            ["Sachbearbeiter"],
            sachbearbeiter
        );
        this.modalHandleSelection((selectedObject) => {
            this.SbId(selectedObject()["Sachbearbeiter_ID"]);
        });
        $("#appointModalmodal").modal("show");
    };

    async getCases() {
        let ExtractCaseNumber = (await RNSAPI.getCases()).Payload.Cases;
        for (let i = 0; i < ExtractCaseNumber.length; i++) {
            this.AllCases.push(ExtractCaseNumber[i].Registernummer);
        }
    }

    changedRegNr() {
        var sugg = "";
        document.getElementById("suggestions").innerHTML = "";
        let Entry = (<HTMLInputElement>document.getElementById("caseNumberInput"))
            .value;
        if (Entry.length > 3) {
            for (let i = 0; i < this.AllCases().length; i++) {
                if (ko.toJS(this.AllCases()[i]).includes(Entry)) {
                    sugg += '<option value="' + this.AllCases()[i] + '"/>';
                }
            }
            document.getElementById("suggestions").innerHTML = sugg;
        }
    }

    changedStartTime() {
        let start = ko.toJS(this.startTime);
        for (let i = 0; i < this.times.length; i++) {
            if (start == this.times[i]) {
                this.endTime(this.times[i + 1]);
            }
        }
    }

    changedStartDate() {
        if (moment(this.startDate(), 'YYYY-MM-DD').isAfter(moment(this.endDate(), 'YYYY-MM-DD'))) {
            this.endDate(this.startDate());
            this.changedStartTime()
        }
    }

    async changedwholeday() {
        if (this.wholeday()) {
            this.wholeday(false);
        } else {
            this.wholeday(true);
        }
    }

    closeModal() {
        $(`#${this.givenId}`).modal("hide");
    }

    async getFristenKalender(): Promise<any> {
        let result = { FKA: "", FKAId: "" };
        if (ko.toJS(this.CaseId) !== "" && ko.toJS(this.CaseId) !== null) {
            let res = (await RNSAPI.getExtendedCase(ko.toJS(this.CaseId))).Payload;
            result.FKA = res.Akte.DeadlineCalendarAccount;
            result.FKAId = res.Akte.DeadlineCalendarAccountId;
            this.attendees(res.Akte.WeitereSachbearbeiterRecords);
            //this.SbId(res.Akte.SachbearbeiterId);
            this.currentRubrum(res.Akte.Rubrum);
            this.setInstance(res.Instances);
        }
        return result;
    }

    async getFKA() {
        let AccountId = await this.getFristenKalender();
        let result = (await RNSAPI.getOutlook());
        if(result.Type === "GetAccountsSuccessful"){
            let res = result.Payload.OutlookAccounts;
            if (res.length > 0) {
                let account = "";
                let accountId = "";
                for (let acc of res) {
                    if (acc.OutlookAccountID === AccountId.FKAId) {
                        account = acc.UserLogin;
                        accountId = acc.OutlookAccountID;
                    }
                }
                this.fkAName(account);
                this.fKAId(accountId);
            }
            
            let user = RNSAPI.getCurrentLoggedInUser();

            if(user.UseVerificationWorkflow === "1" && user.LawFirmId !== "100154")
            {
                if(document.getElementById("notifyClerkAppointment"))
                    (<HTMLButtonElement>document.getElementById("notifyClerkAppointment")).removeAttribute("checked");
            }  
        }
    }

    constructor(params: any) {
        if (params && params.hideoncreate) {
            if (typeof params.hideoncreate == "boolean")
                this.hideoncreate(params.hideoncreate);
            else this.hideoncreate = params.hideoncreate;
        } else this.hideoncreate(false);

        if (params.prefix) {
            if (params.mode && params.mode() === 'edit') {
                const items = params.data()
                this.fillAppointmentData(items.time)
                this.IsEditMode(true);
            } else {
                this.fillNewAppointmentData(params.data)
            }
            this.givenId = params.prefix + this.givenId;
        }
        this.parentVM = params.vm;

        for (var i = 0; i < 24; i++) {
            this.times.push(this.adjustDigits(i, 2) + ":" + "00");
            this.times.push(this.adjustDigits(i, 2) + ":" + "30");
        }

        this.getCases();
        this.getFKA();

        Postbox.publisher().subscribe((id) => this.fillAppointmentData(id), "FillAppointmentDataWithID");
        Postbox.publisher().subscribe(() => this.fillNewAppointmentData(this.newBasicAppointment()), "FillNewAppointmentDataWithID");
        Postbox.publisher().subscribe((caseId) => this.fillCaseIdData(this.newBasicAppointment(), caseId), "FillNewAppointmentDataWithCaseID");
    }
}

let html = fs.readFileSync(__dirname + "/appointmentDialog.html", "utf8");

ko.components.register("appointment-dialog-view", {
    viewModel: appointmentDialogViewModel,
    template: html,
});
