import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Postbox } from "../postbox/postbox";
export class CompleteAppointmentDialogViewModel {

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    givenId = "CompleteAppointmentDialogExt";    
    AppointmentId = ko.observable();
    isCompletion = ko.observable(true);
    checkComplete = ko.observable<boolean>(false);

    async completeAppointment() {
        this.checkComplete(true);
        if(ko.toJS(this.isCompletion)){
            await RNSAPI.completeAppointment(ko.toJS(this.AppointmentId())).then((response) => {
                Postbox.publisher().publish(true, "updateTFW");
            });
        }
        else
        {
            Postbox.publisher().publish(true, "updateTFW");
            await RNSAPI.deleteAppointment(ko.toJS(this.AppointmentId())).then((response) => {
                Postbox.publisher().publish(true, "updateTFW");
            });
        }
        this.clearModal();
    }

    clearModal(): void {
        $("#CompleteAppointmentDialogExt").modal('hide');
        this.AppointmentId("");
        this.isCompletion(true);
        this.checkComplete(false);
    }    

    constructor() {
        Postbox.publisher().subscribe((appointmentData) => {
            $("#CompleteAppointmentDialogExt").modal('show');
            this.AppointmentId(appointmentData.Id);
            this.isCompletion(ko.toJS(appointmentData.Complete));            
        }, "CompleteAppointmentModalData");
    }
}


let html = fs.readFileSync(__dirname + "/CompleteAppointmentDialog.html", "utf8");

ko.components.register("complete-appointment-dialog-view", {
  viewModel: CompleteAppointmentDialogViewModel,
  template: html,
});