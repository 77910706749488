import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Postbox } from "../postbox/postbox";

export class CompleteDeadlineDialogViewModel {

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    givenId = "CompleteDeadlineDialogExt";

    
    deadlineReason = ko.observable<string>("");
    deadlineId = ko.observable();
    deadlineCaseId = ko.observable<string>("");
    checkComplete = ko.observable<boolean>(false);
    completionReason = ko.observable<string>("");


    async completeDeadline() {
        if(ko.toJS(this.completionReason)==="")
        {
            $("#compleationReasonContainer").addClass("has-error");
            return;
        }
        else
            $("#compleationReasonContainer").removeClass("has-error");

        this.checkComplete(true);
        
        this.deadlineId().ReasonForCompletion = ko.toJS(this.completionReason);

        await RNSAPI.completeDeadline(this.deadlineId(), ).then((response) => {
            Postbox.publisher().publish(true, "updateTFW");
            this.clearModal();
        });
    }

    clearModal(): void {
        $("#CompleteDeadlineDialogExt").modal('hide');
        this.deadlineId("");
        this.deadlineReason("");
        this.deadlineCaseId("");
        this.checkComplete(false);
        this.completionReason("");
    }

    

    constructor() {
        Postbox.publisher().subscribe((deadlineData) => {
            $("#CompleteDeadlineDialogExt").modal('show');
            this.deadlineCaseId(deadlineData.CaseId);
            this.deadlineReason(deadlineData.Reason);
            this.deadlineId(deadlineData.Id);
        }, "CompleteDeadlineModalData");
    }
}


let html = fs.readFileSync(__dirname + "/CompleteDeadlineDialog.html", "utf8");

ko.components.register("complete-deadline-dialog-view", {
  viewModel: CompleteDeadlineDialogViewModel,
  template: html,
});