import { RNSAPI } from "../api";

export class RolesAndRights {
    public static hasAdminRights(): boolean {
        const rolesAndRights = RNSAPI.getCurrentLoggedInUser();
        if(rolesAndRights) {
            if(rolesAndRights.isadmin === '1') {
                return true;
            }
        }
        return false;
    }

    public static isLawFirmLeader(): boolean {
        const rolesAndRights = RNSAPI.getCurrentLoggedInUser();
        if(rolesAndRights) {
            if(rolesAndRights.LawFirmLeader === '1') {
                return true;
            }
        }
        return false;
    }

    public static isViewerRole(): boolean {
        const rolesAndRights = RNSAPI.getCurrentLoggedInUser();
        if(rolesAndRights) {
            if(rolesAndRights.Role === '4') {
                return true;
            }
        }
        return false;
    }

    public static isLicenseExpired(): boolean {
        const rolesAndRights = RNSAPI.getCurrentLoggedInUser();
        if(rolesAndRights) {
            if(rolesAndRights.LicenseExpired === '1') {
                return true;
            }
        }
        return false;
    }
}