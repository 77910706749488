export enum UserRole {
    //Anwalt
    Lawyer = 0,
    //Refa
    Paralegal = 1
}

export class User {
    username: string
    role: UserRole

    constructor(username: string, role: UserRole) {
        this.username = username;
        this.role = role;
    }
}

export class UserData {
    //make all properties immutable
    public readonly lastVisitedCaseId: string

    constructor(lastVisitedCaseId: string) {
        this.lastVisitedCaseId = lastVisitedCaseId;
    }
}

export class UserDataWrapper {
    Value: string

    constructor(data: UserData) {
        this.Value = JSON.stringify(data);
    }
}