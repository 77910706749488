import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment"
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import '../dialog/dialog';
import '../address/overview';
import { Postbox } from "../postbox/postbox";

class AddressModalViewModel {

    newPerson() {

        let obj = {
            FirstName: ko.observable(''),
            Address: ko.observable(''),
            Adress2: ko.observable(''),
            Adress2CityName: ko.observable(''),
            Adress2CountryID: ko.observable(''),
            Adress2PLZ: ko.observable(''),
            Adresszusatz: ko.observable(''),
            AnredeBriefkopf: ko.observable(''),
            AnredeDirekt: ko.observable(''),
            Arbeitgeber1: ko.observable(''),
            Arbeitgeber2: ko.observable(''),
            Arbeitgeber3: ko.observable(''),
            Bank: ko.observable(''),
            BankId: ko.observable(''),
            BirthName: ko.observable(''),
            BirthDate: ko.observable(''),
            CentralPhone: ko.observable(''),
            CityName: ko.observable(''),
            CountryID: ko.observable(''),
            CountryName: ko.observable(''),
            DebitorenKontoNummer: ko.observable(''),
            DisplayName: ko.observable(''),
            Ehepartner: ko.observable(''),
            EmailAddress: ko.observable(''),
            Fax: ko.observable(''),
            GesetzlicherVertreter1: ko.observable(''),
            GesetzlicherVertreter1Id: ko.observable(''),
            GesetzlicherVertreter2: ko.observable(''),
            GesetzlicherVertreter2Id: ko.observable(''),
            GesetzlicherVertreter3: ko.observable(''),
            GesetzlicherVertreter3Id: ko.observable(''),
            GesetzlicherVertreter4: ko.observable(''),
            GesetzlicherVertreter4Id: ko.observable(''),
            IBAN: ko.observable(''),
            Imex: ko.observable(''),
            KennzeichenMB: ko.observable(''),
            KontoNummer: ko.observable(''),
            KreditorenKontoNummer: ko.observable(''),
            MBType: ko.observable(''),
            MobilePhone: ko.observable('+49'),
            Name1: ko.observable(''),
            Name2: ko.observable(''),
            Name3: ko.observable(''),
            Note1: ko.observable(''),
            Note2: ko.observable(''),
            Note3: ko.observable(''),
            Note4: ko.observable(''),
            Note5: ko.observable(''),
            PLZPostfach: ko.observable(''),
            PersonalausweisNr: ko.observable(''),
            Phone1: ko.observable('+49'),
            Phone2: ko.observable(''),
            Phone3: ko.observable(''),
            Postfach: ko.observable(''),
            Rechtsform: ko.observable(''),
            Sonstige1: ko.observable(''),
            Sonstige2: ko.observable(''),
            SpezielleAnrede2: ko.observable(''),
            URL: ko.observable(''),
            ZipCode: ko.observable(''),
            CreatedAt: ko.observable(''),
            RSVersId: ko.observable(''),
            RSVersNr: ko.observable(''),
            RSSelbstbehalt: ko.observable(''),
            PayPalAddress: ko.observable(''),
            Keyword: ko.observable('')
        };

        return ko.observable(obj);
    };

    newGericht() {
        let obj = {
            Address: ko.observable(''),
            AnredeBriefkopf: ko.observable(''),
            AnredeDirekt: ko.observable(''),
            Bank: ko.observable(''),
            BankId: ko.observable(''),
            CityName: ko.observable(''),
            DisplayName: ko.observable(''),
            EmailAddress: ko.observable(''),
            Fax: ko.observable(''),
            KennzeichenMB: ko.observable(''),
            Keyword: ko.observable(''),
            KontoNummer: ko.observable(''),
            Name1: ko.observable(''),
            Name2: ko.observable(''),
            Name3: ko.observable(''),
            Phone1: ko.observable(''),
            Postfach: ko.observable(''),
            URL: ko.observable(''),
            ZipCode: ko.observable('')
        }
        return ko.observable(obj);
    }

    BIC = ko.observable('');
    bankDisplayName = ko.observable('');

    pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    }

    inf() {
        let obj = {
            infTitle: ko.observable(this.infoTitle()),
            infMessage: ko.observable(this.infoMessage()),
            infshow: ko.observable(this.modialAutoHide()),
            type: ko.observable(this.selectedType())
        }
        return obj;
    }

    modalHandleSelection = ko.observable();

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);

    extendedDataIsShown = ko.observable(false);

    person = this.newPerson();
    gericht = this.newGericht();


    types = ko.observableArray(["Mandant", "Gegner", "Sonstige", "Anwalt"]);

    GeVeBe = ko.observable(false);

    requiredVertreter = ko.observableArray(["Mandant", "Gegner", "Sonstige"]);
    selectedType = ko.observable("Alle");
    IsEditMode = ko.observable(false);
    columns = ko.observableArray(['FirstName', 'Name1', 'CityName', 'Actions']);
    columnHeaders = ko.observableArray(['Vorname', 'Nachname', 'Ort']);

    infoMessage = ko.observable('');
    infoTitle = ko.observable('');
    modialAutoHide = ko.observable(false);
    showRF = ko.observable(false);

    natrualPerson = ko.observable(true);

    rachts = ko.observableArray([]);
    rachtsPerson = ko.observableArray([]);
    briefkopf = ko.observableArray([]);

    readMode = ko.observable(false);

    needNoModal = ko.observable(false);

    createdAt = ko.observable('')

    CaseNumber = ko.observable('');

    isLawyer = ko.observable(false);

    showExtendedData() {
        if (ko.toJS(this.extendedDataIsShown)) {
            this.extendedDataIsShown(false);
        }
        else {
            this.extendedDataIsShown(true);
        }
    }

    async pickGVertreter() {
        if (this.readMode() === false)
            Postbox.publisher().publish(this.selectedType(), "showGV");
    }

    setGV(type) {
        this.person().GesetzlicherVertreter1Id(type);
        document.getElementById("allAddressModal").setAttribute("style", "overflow-y: auto; overflow-x: hidden; padding-right: 17px; display: block;");
    }

    selectOption = (data) => {
        let a = ko.toJS(this.briefkopf);
        for (let i = 0; i < a.length; i++) {
            if (data === a[i].NameID) {
                this.person().Rechtsform(a[i].NameID);
                this.person().AnredeBriefkopf(a[i].Briefanrede);
                this.person().AnredeDirekt(a[i].Anrede);
                this.showRF(false);
                break;
            }
        }

        if (ko.toJS(this.person().Rechtsform) === "Herr" || ko.toJS(this.person().Rechtsform) === "Frau" || ko.toJS(this.person().Rechtsform) === "Divers") {
            this.natrualPerson(true);
        }
        else
            this.natrualPerson(false);
    }

    async getCity() {

        const { Type, Payload } = (await RNSAPI.getPostcode(this.person().ZipCode()))

        if (Type === "GetPostcodeResult" && Payload.Cities.length > 0) {
            this.person().CityName(Payload.Cities[0])
        } else {
            this.person().CityName('')
        }
    }

    async setBank() {
        if (ko.toJS(this.person().Bank) !== null) {
            if (ko.toJS(this.person().IBAN) !== '') {
                let iban = ko.toJS(this.person().IBAN).toString().replace(/ /g, "");
                let index = 0;
                let lastindex = 0;
                let intervall = 1;
                let ib = [];
                for (let i = 0; i < iban.length; i++) {
                    if (intervall === 4) {
                        ib[index] = iban.substring(lastindex, i + 1);
                        intervall = 1;
                        lastindex = i + 1;
                        index++;
                    }
                    else {
                        ib[index] = iban.substring(lastindex, i + 1);
                        intervall++;
                    }

                }

                let formattedIBAN = ib[0]

                for (let i = 1; i < ib.length; i++) {
                    formattedIBAN = formattedIBAN + ' ' + ib[i];
                }
                this.person().IBAN(formattedIBAN);

                iban = ko.toJS(this.person().IBAN).toString().replace(/ /g, "");

                if (iban.length >= 12) {
                    if (ko.toJS(this.person().BankId) === '')
                        this.person().BankId(iban.substring(4, 12));
                    let res = await RNSAPI.GetBank(iban.substring(4, 12));
                    if (res.Type === "BankMatchFound") {
                        this.person().Bank(res.Payload.Banks[0]);
                        this.bankDisplayName(res.Payload.Banks[0].DisplayName);
                        this.BIC(res.Payload.Banks[0].BIC);
                    }
                }
            }
            else {
                this.BIC('');
                this.bankDisplayName('');
            }
        }
        else {
            this.BIC('');
            this.bankDisplayName('');
        }
    }

    async setPayPal() {
        if (ko.toJS(this.selectedType) === 'Mandant') {
            if (ko.toJS(this.person().PayPalAddress) === '') {
                this.person().PayPalAddress(ko.toJS(this.person().EmailAddress));
            }
        }
    }

    pickRechtsform = async () => {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        let x = [];
        let y = [];
        let z = [];
        let a = [];
        let b = [];
        let c = [];
        for (let i = 0; i < rechtsformen.length; i++) {
            if (rechtsformen[i].NameID != "") {
                if (rechtsformen[i].NameID === "Herr" || rechtsformen[i].NameID === "Frau" || rechtsformen[i].NameID === "Divers") {
                    a.push(rechtsformen[i].NameID);
                    b.push(rechtsformen[i].Briefanrede);
                    c.push(rechtsformen[i].Anrede);
                }
                else {
                    x.push(rechtsformen[i].NameID);
                    y.push(rechtsformen[i].Briefanrede)
                    z.push(rechtsformen[i].Anrede)
                }
            }

        }
        this.rachts(x);
        this.rachtsPerson(a);
        this.briefkopf(rechtsformen);
    }

    showSuggestionsRF = async () => {
        this.showRF(true);
        this.pickRechtsform();
    }

    typeChanged(obj, event) {
        if (event.originalEvent) {
            this.person(this.resetObject());
            this.gericht(this.resetObjectGeVeBe());
            if (this.selectedType() === "Gericht" || this.selectedType() === "Versicherung" || this.selectedType() === "Behörde") {
                this.columns(['Name1', 'CityName']);
                this.columnHeaders(['Name', 'Ort']);
                this.GeVeBe(true);
            }
            else {
                this.columns(['FirstName', 'Name1', 'CityName']);
                this.columnHeaders(['Vorname', 'Name', 'Ort']);
                this.GeVeBe(false);
            }
        }
    }

    resetObject() {
        let newPerson = this.newPerson();
        let obj = newPerson();
        return obj
    }

    resetObjectGeVeBe() {
        let newgericht = this.newGericht();
        let obj = newgericht();
        return obj
    }

    async postData() {
        this.infoTitle('')
        this.infoMessage('')
        this.modialAutoHide(false);
        if (ko.toJS(this.selectedType()) !== "Anwalt") {
            Utils.removeHints();
            $(".form-group").each(function () { $(this).removeClass("has-error"); });
            if (!Utils.checkErrors(["Name1"], this.person(), "mandant", [Utils.checkString])) return;
            if (this.requiredVertreter().indexOf(this.selectedType()) !== -1 && !Utils.checkErrors(["Rechtsform"], this.person(), "mandant", [Utils.checkString])) return;
        }
        else {
            this.person().Name1(ko.toJS(this.gericht().DisplayName));
        }

        if (ko.toJS(this.person().Sonstige1) === '')
            this.person().Sonstige1(ko.toJS(this.selectedType().toString()));


        if(this.person().IBAN() !== null) {
            this.person().IBAN(ko.toJS(this.person().IBAN).toString().replace(/ /g, ""));
        }


        if (this.IsEditMode()) {
            try {
                let result = await RNSAPI.updatePerson(this.person(), this.selectedType());
                if (result.Type === "UpdateSuccessful") {
                    this.BIC('');
                    this.bankDisplayName('');
                    this.infoTitle('Erledigt')
                    this.infoMessage('Daten erfolgreich erfasst.')
                    this.modialAutoHide(true);
                    $('#allAddressModal').modal('hide');
                }
                else {
                    $('#allAddressModal').modal('hide');
                    this.infoTitle('Fehler')
                    this.infoMessage('Fehler beim Anlegen: Person mit diesem Suchbegriff existiert bereits.');
                }
            }
            catch (e) {
                let error = Utils.handleError(e);
                $('#allAddressModal').modal('hide');
                this.infoMessage(error['code'] + ': ' + error['message']);
            }
        }
        else {
            try {
                let result = await RNSAPI.createPerson(this.person(), this.selectedType());
                if (result.Type === "AdditionSuccessful") {
                    RNSAPI.createHistoryEntry("", "Stammdaten / " + this.selectedType() + " " + this.person().Name1() + " angelegt");
                    if (this.CaseNumber() !== '') {                        
                        RNSAPI.joinPerson(ko.toJS(result.Payload.Keyword), ko.toJS(this.CaseNumber), this.selectedType());
                    }

                    this.infoTitle('Erledigt')
                    this.infoMessage('Daten erfolgreich erfasst.')
                    this.modialAutoHide(true);
                    $('#allAddressModal').modal('hide');
                    this.person(this.resetObject())
                }
                else {
                    $('#allAddressModal').modal('hide');
                    this.infoTitle('Fehler')
                    this.infoMessage('Fehler beim Anlegen: Person mit diesem Keyword existiert bereits.');
                }
            }
            catch (e) {
                let error = Utils.handleError(e);
                console.log(error)
                $('#allAddressModal').modal('hide');
                this.infoMessage(error['code'] + ': ' + error['message']);
            }
        }

        if (this.CaseNumber() === "")
            Postbox.publisher().publish(this.inf(), "showInfo");
        else {
            Postbox.publisher().publish(this.inf(), "showInfoCase");
        }
    }

    initTooltip() {
        $("[data-toggle='tooltip']").tooltip();
    }

    loadPersonData(person) {
        if (ko.toJS(person().Sonstige1) !== '')
            this.selectedType(ko.toJS(person().Sonstige1));
        if (person().Sonstige1 === "Gericht" || person().Sonstige1 === "Versicherung" || person().Sonstige1 === "Behörde") {
            this.readMode(true);
            this.person(this.resetObject());
            this.GeVeBe(true);
            this.natrualPerson(false);
            this.extendedDataIsShown(true);
            for (let el in this.gericht()) {
                try {
                    this.gericht()[el](ko.toJS(person()[el]));
                } catch{ }

                let dpName = ko.toJS(this.gericht().Name1);
                if(ko.toJS(this.gericht().Name2) !== null)
                    dpName = dpName + ko.toJS(this.gericht().Name2)
                if(ko.toJS(this.gericht().Name3) !== null)
                    dpName = dpName + ko.toJS(this.gericht().Name3)
                this.gericht().DisplayName(dpName);
                this.person().Address(ko.toJS(this.gericht().Address));
                this.person().ZipCode(ko.toJS(this.gericht().ZipCode));
                this.person().CityName(ko.toJS(this.gericht().CityName));
                this.person().Phone1(ko.toJS(this.gericht().Phone1));
                this.person().EmailAddress(ko.toJS(this.gericht().EmailAddress));
                this.person().Fax(ko.toJS(this.gericht().Fax));
                this.person().URL(ko.toJS(this.gericht().URL));
                this.person().AnredeBriefkopf(ko.toJS(this.gericht().AnredeBriefkopf));
                this.person().AnredeDirekt(ko.toJS(this.gericht().AnredeDirekt));
            }
        }
        else if (person().Sonstige1 === "Anwalt") {
            this.natrualPerson(false);
            this.GeVeBe(true);
            this.extendedDataIsShown(true);
            this.isLawyer(true);
            for (let el in this.gericht()) {
                try {
                    this.gericht()[el](ko.toJS(person()[el]));
                } catch{ }

                this.gericht().DisplayName(ko.toJS(this.gericht().Name1) + " " + ko.toJS(this.gericht().Name2) + " " + ko.toJS(this.gericht().Name3));
                this.person().Address(ko.toJS(this.gericht().Address));
                this.person().ZipCode(ko.toJS(this.gericht().ZipCode));
                this.person().CityName(ko.toJS(this.gericht().CityName));
                this.person().Phone1(ko.toJS(this.gericht().Phone1));
                this.person().EmailAddress(ko.toJS(this.gericht().EmailAddress));
                this.person().Fax(ko.toJS(this.gericht().Fax));
                this.person().URL(ko.toJS(this.gericht().URL));
                this.person().AnredeBriefkopf(ko.toJS(this.gericht().AnredeBriefkopf));
                this.person().AnredeDirekt(ko.toJS(this.gericht().AnredeDirekt));
            }

        }
        else {
            this.GeVeBe(false);
            this.readMode(false);
            for (let el in this.person()) {
                try {
                    this.person()[el](ko.toJS(person()[el]));
                } catch{ }
            }
            if (this.person().CreatedAt())
                this.createdAt(ko.toJS(moment(this.person().CreatedAt()).format("DD.MM.YYYY")));
            else
                this.createdAt("");

            if (ko.toJS(this.person().Rechtsform) === "Herr" || ko.toJS(this.person().Rechtsform) === "Frau" || ko.toJS(this.person().Rechtsform) === "Divers" || ko.toJS(this.person().Rechtsform) === "") {
                this.natrualPerson(true);
            }
            else
                this.natrualPerson(false);
        }
    }

    constructor(params: any) {
        if (params.needNoModal)
            this.needNoModal(params.needNoModal);

        Postbox.publisher().subscribe(() => {
            this.isLawyer(false);
            if (params.type !== undefined)
                this.selectedType(ko.toJS(params.type));
            this.types(["Mandant", "Gegner", "Sonstige", "Gericht", "Versicherung", "Behörde", "Anwalt"]);
            this.extendedDataIsShown(false);
            this.loadPersonData(params.person)
            if (params.readM)
                this.readMode(params.readM);
            if (params.isEditMode)
                this.IsEditMode(params.isEditMode);
            else
                this.IsEditMode(false);
            if (params.fromCase)
                this.CaseNumber(params.fromCase);
            else
                this.CaseNumber('');

            this.initTooltip();

        }, 'updateAddressModal')

        Postbox.publisher().subscribe((type) => {
            this.isLawyer(false);
            this.types(["Mandant", "Gegner", "Sonstige", "Anwalt"]);
            this.person(this.resetObject());
            this.gericht(this.resetObjectGeVeBe());
            if (type === "Alle")
                type = "Mandant";
            this.selectedType(type);
            this.BIC('');
            this.bankDisplayName('');
            this.IsEditMode(false);
            this.extendedDataIsShown(false);
            this.natrualPerson(true);
            this.GeVeBe(false);
            this.readMode(false);
            if (this.selectedType() === "Gericht" || this.selectedType() === "Versicherung" || this.selectedType() === "Behörde" || this.selectedType() === "Anwalt") {
                this.GeVeBe(true);
                this.extendedDataIsShown(true);
                this.natrualPerson(false);
                if (this.selectedType() === "Anwalt")
                    this.isLawyer(true);
            }
            else {
                this.GeVeBe(false);
            }
            if (params.fromCase)
                this.CaseNumber(params.fromCase);
            else
                this.CaseNumber('');

            this.initTooltip();
        }, 'clearAddressModal')

        Postbox.publisher().subscribe((GV) => {
            this.setGV(GV);
        }, 'ChooseGVertreter')

        this.person().IBAN.subscribe(() => {
            this.setBank();
        })
    }
}

let html = fs.readFileSync(__dirname + '/address_form.html', 'utf8');

ko.components.register("address_form-view", {
    viewModel: AddressModalViewModel,
    template: html
});
