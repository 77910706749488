import * as ko from "knockout";

export class Postbox {
    pb: any;
    private static pb: any = new ko.subscribable();

    public static publisher() {
        return {    
            subscribe: (handler, topic) => {
                Postbox.pb.subscribe(handler, null, topic)
            },        
            publish: (message, topic) => {
                Postbox.pb.notifySubscribers(message, topic);
            }
        }
    }
}