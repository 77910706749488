import { ThumbSettings } from '@syncfusion/ej2/charts';
import * as fs from 'fs';
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Postbox } from '../postbox/postbox';

class FastCasePersonViewModel {
    constructor(params) {
        this.client = params.client;
        this.index = params.index;
        this.prefix = params.prefix;
    }

    client;
    index;
    prefix
    showRF = ko.observable(false);
    rachts = ko.observableArray([]);
    allLegalforms= ko.observableArray([]);
    allPersonforms= ko.observableArray([]);
    rachtsPerson = ko.observableArray([]);
    briefkopf = ko.observableArray([]);
    isLoaded = ko.observable(false);
    modalHandleSelection = ko.observable();
    addresses = ko.observableArray([]);
    filterBeteiligte = ko.observable('');        
    vis = ko.observable(false);
    isOpeningPersons = ko.observable(false);

    toggleShown() {
        this.client.isShown(!this.client.isShown());
    }

    toggleMoreData() {
        this.client.isMoreShown(!this.client.isMoreShown());
    }

    showExtendedData = (id) => {
        let el = document.getElementById(id);
        if (el.classList.contains("collapse"))
            el.classList.remove("collapse");
        else
            el.classList.add("collapse");
    }

    
    async pickPerson(parent) {
        this.filterBeteiligte('');
        Postbox.publisher().publish("", "ResetFilter");
        this.vis(true);
        this.isOpeningPersons(true)
        let addrType = 2;
        if (this.client.personType() === "gegner") {
            addrType = 3;
        }
        this.isLoaded(false);
        let addresses = [];
        await RNSAPI.getAdressPagin(addrType, "").then(async (addressesinp) => {
            let addr = addressesinp as any;
            addresses = addr;
            let ad = addresses.map((o) => {
                o.actionHandlers = [{
                    icon: "user-check",
                    name: 'Adresse ausw\u00e4hlen',
                    action: async () => {
                        let wholeAddress = await this.getExactAddress(o.Keyword, this.client.personType());
                        for (let key in wholeAddress) {
                            if (key === "Keyword") {
                                this.client.data["Keyword"] = ko.observable(wholeAddress["Keyword"]);
                            } else if (typeof (this.client.data[key]) === 'function') {
                                this.client.data[key](wholeAddress[key]);
                            } else {
                                this.client.data[key] = wholeAddress[key];
                            }
                        }
                        $('#pickPersonsModal').modal('hide');
                        this.vis(false);
                    }
                }];
                return o;
            });

            this.addresses(addresses);
            this.isLoaded(true);
            $('#pickPersonsModal').modal('show');
            this.isOpeningPersons(false);
        }).catch((error) => {
            console.log(error);
            addresses = [];
        });
    }

    modalSelectedObject = ko.observable(null);

    modalSelect = (obj, event) => {

        if ($(event.target.parentElement).hasClass("active")) {
            this.modalPick();
        }
        else {
            $("#modalTable tr").removeClass("active");
            $(event.target.parentElement).addClass("active");
            this.modalSelectedObject(obj);
        }
    }

    modalPick() {
        if (this.modalHandleSelection !== null) this.modalHandleSelection()(this.modalSelectedObject);
        $('#pickPersonsModal').modal('hide');
        this.vis(false);
    };

    async getExactAddress(keyword, addressType) {
        let addres = (await RNSAPI.getPersonForKeyword(keyword, addressType)).Payload.Address;
        return addres;
    }
    
    pickRechtsform = async () => {
        let rechtsformen = (await RNSAPI.getRechtsformen()).Payload.LegalForms;
        let x = [];
        let y = [];
        let z = [];
        let a = [];
        let b = [];
        let c = [];
        for (let i = 0; i < rechtsformen.length; i++) {
            if (rechtsformen[i].NameID != "") {
                if (rechtsformen[i].NameID === "Herr" || rechtsformen[i].NameID === "Frau" || rechtsformen[i].NameID === "Divers") {
                    a.push(rechtsformen[i].NameID);
                    b.push(rechtsformen[i].Briefanrede);
                    c.push(rechtsformen[i].Anrede);
                }
                else {
                    x.push(rechtsformen[i].NameID);
                    y.push(rechtsformen[i].Briefanrede)
                    z.push(rechtsformen[i].Anrede)
                }
            }

        }
        
        this.allLegalforms(x);
        this.allPersonforms(a);

        this.rachts(x);
        this.rachtsPerson(a);
        this.briefkopf(rechtsformen);
    }

    selectOption = (data) => {
        let a = ko.toJS(this.briefkopf);
        for (let i = 0; i < a.length; i++) {
            if (data === a[i].NameID) {
                this.client.data.Rechtsform(a[i].NameID)
                this.client.data.AnredeBriefkopf(a[i].Briefanrede)
                this.client.data.AnredeDirekt(a[i].Anrede)
                this.showRF(false);
                break;
            }
        }
    }

    showSuggestionsRF = async () => {
        this.showRF(true);
        this.pickRechtsform();
    }

    changedClient = async () => {
        var elemntID = ko.toJS(this.prefix)+"-rechtsform-"+ko.toJS(this.index);
        var HTMLSearchValue = (<HTMLInputElement>document.getElementById(elemntID)).value

        if(HTMLSearchValue)
        {
            let filteredItems = [];
            let filteredItemsPersons = [];
            for(let i=0; i<ko.toJS(this.allLegalforms).length; i++){
                if(ko.toJS(this.allLegalforms)[i].toLowerCase().includes(HTMLSearchValue.toLowerCase()))  
                {
                    filteredItems.push(ko.toJS(this.allLegalforms)[i]);
                }
            }
            for(let i=0; i<ko.toJS(this.allPersonforms).length; i++){
                if(ko.toJS(this.allPersonforms)[i].toLowerCase().includes(HTMLSearchValue.toLowerCase()))  
                {
                    filteredItemsPersons.push(ko.toJS(this.allPersonforms)[i]);
                }
            }

            if(filteredItems.length === 1 && filteredItemsPersons.length === 0)
            {
                this.selectOption(filteredItems[0])
            }

            if(filteredItems.length === 0 && filteredItemsPersons.length === 1)
            {
                this.selectOption(filteredItemsPersons[0])
            }

            this.rachtsPerson(filteredItemsPersons);
            this.rachts(filteredItems);
        }
    }
}

let html = fs.readFileSync(__dirname + '/easycaseperson.html', 'utf8');

ko.components.register("easycaseperson-view", {
    viewModel: FastCasePersonViewModel,
    template: html
});
