import * as moment from "moment";


export class Utils {
    public static base64ToBlob(b64Data: string, contentType?: string, sliceSize?: number) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        let blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    public static base64ToArrayBuffer(base64) {
        let binary_string = window.atob(base64);
        let len = binary_string.length;
        let bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    public static arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    public static uint8ArrayToBase64(bytes) {
        let binary = '';
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    public static base64ToUint8Array(base64) {
        let raw = atob(base64);
        let uint8Array = new Uint8Array(new ArrayBuffer(raw.length));
        for (let i = 0; i < raw.length; i++) {
            uint8Array[i] = raw.charCodeAt(i);
        }

        return uint8Array;
    }

    public static partition(array, n) {
        let copy = array.slice();
        return copy.length ? [copy.splice(0, n)].concat(Utils.partition(copy, n)) : [];
    }

    //can hopefully be replaced by https://caniuse.com/#search=URLSearchParams at some point
    public static getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    public static uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        })
    }

    /**
    * Parses XMLHttpRequest getAllResponseHeaders into a key-value map
    */
    public static getResponseHeaderObject(headers) {
        let list = {}
        let pairs

        if (!headers) {
            return list
        }

        pairs = headers.split('\u000d\u000a')

        for (let i = 0, length = pairs.length; i < length; i++) {
            let pair = pairs[i]

            // Can't use split() here because it does the wrong thing
            // if the header value has the string ": " in it.
            let index = pair.indexOf('\u003a\u0020')

            if (index > 0) {
                let key = pair.substring(0, index)
                let val = pair.substring(index + 2)
                list[key] = val
            }
        }

        return list
    }

    public static createCookie(name: string, value: string, days: number) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        //document.cookie = name + "=" + value + expires + "; path=/";
        document.cookie = name + "=" + value + expires + "; sameSite=lax; Secure=true; path=/;"; //HttpOnly=true;
    }

    public static readCookie(name: string) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    public static eraseCookie(name: string) {
        Utils.createCookie(name, "", -1);
    }

    public static matchAny(value: string, set: Array<string>) {
        let valueTransformed = value.replace(/\s/g, '');
        for (let element of set) {
            if (valueTransformed.indexOf(element) !== -1) {
                return true;
            }
        }

        return false;
    }

    public static trunc(s: string, n: number) {
        return (s.length > n) ? s.substr(0, n - 1) + '…' : s;
    };

    public static checkString = (str: string) => {return {validation: str !== null && str.trim() !== '' , message: ' ist Pflichtfeld'}};
    public static checkPhone = (str: string) => {return {validation: str !== null && str.trim() !== '' && str !== '+49' , message: ' ist Pflichtfeld'}};

    public static checkMultipleErrors(fields: Array<string>, obj: any, id: string, checks: any) {
        let count = 0;
        for (let i = 0; i < fields.length; i++) {
            let field = fields[i];
            /* console.log(obj)
            console.log(obj[field])
            console.log(fields) */
            for (let check of checks) {
                //console.log(check)
                let field_id = "#" + id + field;

                let field_check;
                try {
                    field_check = check(obj[field]());
                } catch(ex) {
                    field_check = check(obj[field]);
                }
                

                if (!field_check['validation']) {
                    //console.log("#" + id + field)
                    // $("#" + id + field).parent().addClass("has-error");
                    $(field_id).closest('.form-group').addClass("has-error")
                    let hint = $(field_id).closest('.form-group').children('label').get(0).innerHTML + field_check['message'];
                    $(field_id).closest('.form-group').find('.help-text').remove()
                    $(field_id).closest('.form-group').append('<div class="help-text">' + hint + '</div>');
                    count++;
                    // return false;
                }
                else {
                    $("#" + id + field).parent().removeClass("has-error");
                }
            }
        };
        return count ? false: true

        // return true;
    };

    public static checkSameValues(fields: Array<string>, obj: any, id: string, checks: Array<(arg0, any) => boolean>) {
        let count = 0;
        const length = fields.length
        for (let i = 0; i < length; i++) {
            let field = fields[i];

            if (i + 1 < length) {
                let nextField = fields[i + 1];
                for (let check of checks) {
                    if (!check(obj[field](), obj[nextField]())) {
                        $("#" + id + field).parent().addClass("has-error");
                        $("#" + id + nextField).parent().addClass("has-error");
                        count++;
                    }
                    else {
                        $("#" + id + field).parent().removeClass("has-error");
                    }
                }
            }
        };
        return count ? false: true
    };

    public static removeHints(){
        $('.help-text').each(function(){$(this).remove()})
    }
    public static checkErrors(fields: Array<string>, obj: any, id: string, checks: Array<(any) => Object>) {
        for (let i = 0; i < fields.length; i++) {
            let field = fields[i];
            for (let check of checks) {
                let field_id = "#" + id + field
                let field_check = check(obj[field]());
                if (!field_check['validation']) {
                    // $(field_id).parent().addClass("has-error");
                    $(field_id).closest('.form-group').addClass("has-error")
                    let hint = $(field_id).closest('.form-group').children('label').get(0).innerHTML + field_check['message'];
                    // $(field_id).closest('.help-text').remove()
                    $(field_id).closest('.form-group').find('.help-text').remove()
                    $(field_id).closest('.form-group').append('<div class="help-text">' + hint + '</div>');
                    // $(field_id).parent().append('<div class="help-text">' + hint + '</div>');
                    $(field_id).get(0).scrollIntoView({behavior: "smooth", block: "end"});
                    setTimeout(function() { $(field_id).focus() }, 100);
                    return false;
                } else {
                    // $(field_id).parent().removeClass("has-error");
                    $(field_id).closest('.form-group').removeClass("has-error");
                }
            }
        };

        return true;
    };

    public static checkEmail(email: string) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return {validation: re.test(String(email).toLowerCase()) , message: ' ist Pflichtfeld'};
    }

    public static isToday(m: moment.Moment): boolean {
        let today = moment.utc();
        let start = today.clone().startOf("day");
        let end = today.clone().endOf("day");

        return m.isBetween(start, end, null, "[]");
    }

    public static formatCurrency(value) {
        if (!value) return "";
        let currency = value;
        currency = parseFloat(currency.toString().replace(/,/g, "."));
        currency = currency.toFixed(2).toString();
        currency = currency.replace(/[.]/, ",");
        currency = currency.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return currency;
    }
    
    public static readonly AcceptedMimeTypes = [
        //OpenOffice
        "application/vnd.oasis.opendocument.text",
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/vnd.oasis.opendocument.presentation",
        //Microsoft Office (old)
        "application/msword",
        "application/vnd.ms-excel",
        "application/vnd.ms-powerpoint",
        //Microsoft Office (new)
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        //audio
        "audio/ogg",
        //others
        "text/csv",
        "application/rtf",
        "text/plain",
        "application/pdf",
        "message/rfc822"
    ];

    static assertNever(x: never): never {
        throw new Error("Unexpected object: " + x);
    }

    static replicate<T>(n: Number, f: () => T): T[] {
        const result: T[] = [];

        for (let i = 0; i < n; i++) {
            result.push(f());
        }

        return result;
    }

    public static handleError(error) {

        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 4xx
             */
            let message = {};
            switch (error.response.status) {
              case 401:
                message = { code: 401, message: 'Authentication token is invalid.'}
                break;
              case 400:// MARK: - Bad request
                message = { code: 400, message: 'There were validation errors'}
                break;
              case 404:
                message = { code: 404, message: `Route does not exist`} // TODO: - Customize me
                break;
              default:
                message = { code: error.response.status, message: error.response.data.error }; // Customized error message/responses
            }
            return message
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            return { code: 0, message: 'Fehler aufgetreten.'}
          } else {
            // Something happened in setting up the request and triggered an Error
            return  { code: 500, message: 'Speichern fehlgeschlagen. Keine Verbindung zum rainmaker Server: Fehlercode 500. Bitte überprüfen Sie Ihre Internetverbindung. Falls der Fehler weiterhin vorliegt, kontaktieren Sie uns unter der Telefonnummer 0602220508789'}
        }
    }
    public static parseXML(str) {
        if (str.indexOf("<root>") < 0) {
          str = '<root>' + str + '</root>';
        }
      
        const parser = new DOMParser();
        return parser.parseFromString(str, "text/html")
    }
}
